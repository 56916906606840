
import {useStore, updateTheStore} from './newState'
import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image, TextArea} from "semantic-ui-react";
import * as fb from './firebaseHandler'

import {getCopyOf} from './utilityFunctions'



function changeYearOfEvent(newVal, event) {


	if(isNaN(newVal)) {
		return
	}	

	event.year = newVal

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

function changeMonthOfEvent(newVal, event) {


	if(isNaN(newVal)) {
		return
	}	

	event.month = newVal

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

function changeTitleOfEvent(newVal, event) {



	event.title = newVal

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

function changeDescOfEvent(newVal, event) {

	

	event.description = newVal

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

function addInvolvedCharacter(character, event) {

	if(!character || !event) {
		return
	}

	if(Object.keys(event.involvedCharacters).includes(character.id)) {
		return
	}

	event.involvedCharacters[character.id] = {id:character.id, name:character.name, approved: false, onTimeline: false}

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

function toggleEvent(event) {

	let state = useStore.getState()

	let characterHandler = getCopyOf(state.characterHandler)

	if(characterHandler.toggledCharacterEvents.includes(event.id)) {
		let index = characterHandler.toggledCharacterEvents.indexOf(event.id)
		characterHandler.toggledCharacterEvents.splice(index,1)
		//delete event
	} else {
		characterHandler.toggledCharacterEvents.push(event.id)
	}

	
	updateTheStore(s => {
        s.characterHandler = characterHandler
    })

}

function removeInvolvedCharacter(character, event) {

	if(!character || !event) {
		return
	}

	if(!Object.keys(event.involvedCharacters).includes(character)) {
		return
	}

	delete event.involvedCharacters[character] 

	updateTheStore(s => {
        s.characterHandler.viewCharacter.events[event.id] = event
    })

}

async function saveEvent(event) {


	event.revision++
	event.saved = true

	let state = useStore.getState()

	let vc = getCopyOf(state.characterHandler.viewCharacter)

	vc.events[event.id] = event

	debugger

	updateTheStore(s => {s.characterHandler.viewCharacter = vc})

	await fb.writeEvent(event)

}

async function deleteEvent(event) {


	event.deleted = true
	event.saved = true

	let state = useStore.getState()

	let vc = getCopyOf(state.characterHandler.viewCharacter)

	vc.events[event.id] = event

	updateTheStore(s => {s.characterHandler.viewCharacter = vc})

	await fb.writeEvent(event)

}

async function toggleTimeline(event) {


	let state = useStore.getState()

	let vc = getCopyOf(state.characterHandler.viewCharacter)

	let e = getCopyOf(event)

	if(e.involvedCharacters[vc.id].onTimeline === null) {
		e.involvedCharacters[vc.id].onTimeline = false 
	}

	e.involvedCharacters[vc.id].onTimeline = !e.involvedCharacters[vc.id].onTimeline

	vc.events[event.id] = e

	updateTheStore(s => {s.characterHandler.viewCharacter = vc})

	await fb.writeEvent(e)

}

async function toggleApproved(event) {


	let state = useStore.getState()

	let vc = getCopyOf(state.characterHandler.viewCharacter)

	let e = getCopyOf(event)

	e.involvedCharacters[vc.id].approved = !e.involvedCharacters[vc.id].approved

	vc.events[event.id] = e

	updateTheStore(s => {s.characterHandler.viewCharacter = vc})

	await fb.writeEvent(e)

}

function editEvent(event) {


	event.saved = false

	let state = useStore.getState()

	let vc = getCopyOf(state.characterHandler.viewCharacter)

	vc.events[event.id] = event

	updateTheStore(s => {s.characterHandler.viewCharacter = vc})

}

const CharacterEvent = ({e}) => {


let event = getCopyOf(e)
let state = useStore.getState()
let viewCharacter = state.characterHandler.viewCharacter

let currUser = state.account.uid


if(!e || !viewCharacter) {
	return
}


let viewer = currUser
let creator = event.creator
let owner = viewCharacter.owner


let characters = state.characterHandler.characters
let characterArray = Object.values(characters)

let sortKey = "name"
characterArray.sort(function (a, b) {
            if (!a[sortKey]) {
                return 1
            }
            if (!b[sortKey]) {
                return -1
            }

            let keyA = a[sortKey],
                keyB = b[sortKey]
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })


if(!event.involvedCharacters) {
	event.involvedCharacters = {}
}

let viewerIsCreator = creator === viewer

let viewerIsCharacterOwner = owner === viewer

let allApproved = true

for (const val of Object.values(event.involvedCharacters)) {
	if(!val.approved) {
		allApproved = false
		break
	}
}

if(allApproved) {
	allApproved = event.involvedCharacters[viewCharacter.id].onTimeline
}

if(event.deleted) {
	return <></>

}



if(viewerIsCreator || viewerIsCharacterOwner || allApproved ) {
} else {
	return <></>
}




let isSaved =event.saved
let isToggled = state.characterHandler.toggledCharacterEvents?.includes(event.id) || false




if(!isSaved) {

	return <>
	<div className="event-container">
	<div className="event-name"><strong>Titel: </strong> <Input style={{width:360}} value={event.title} onChange={e=>changeTitleOfEvent(e.currentTarget.value, event)} /> </div>
	<div className="event-save">
	<Button negative onClick={()=>deleteEvent(event)} >Radera händelse </Button>
	<Button positive onClick={()=>saveEvent(event)} >Spara händelse </Button>
	</div>

	<div className="event-date"><strong>Datum:</strong><br /> År:<Input style={{width:60}} value={event.year} onChange={e=>changeYearOfEvent(e.currentTarget.value, event)} /> <br />
	Månad: <Input style={{width:60}} value={event.month} onChange={e=>changeMonthOfEvent(e.currentTarget.value, event)} /> <br /> </div>

	<div className="event-description"> <strong>Beskrivning:</strong><br /><TextArea className="event-text" rows={6} value={event.description} onChange={e=>changeDescOfEvent(e.currentTarget.value, event)}  /></div>
	 
	<div className="event-characters"><strong>Involverade karaktärer:</strong><br /> {Object.keys(event.involvedCharacters).map(ic => <>
	 	<div>{characters[ic].name.slice(0,40)}, {(event.creator == currUser && event.creator != characters[ic].owner) && <><Button size="mini" onClick={()=>removeInvolvedCharacter(ic, event)} >X </Button></>}  </div>
	 	</>)}
	 	<select onChange={e => addInvolvedCharacter(characters[e.currentTarget.value], event)}> 
                          <option value={""}>-</option>
                          {characterArray.map(c => 
                          	<option value={c.id}>{c.name.slice(0,40)}</option> 
                          	)}
      	</select> </div>
      	<div></div>
	</div>
	</>

} else if(isToggled) {

	return <>
	<div className="event-view-container">
	<div className="event-view-name">{event.title} </div>
	<div className="event-view-date">År: {event.year}, Månad: {event.month}</div>
	<div className="event-view-description"><strong>Beskrivning:</strong><br />{event.description}</div>
	<div className="event-save">
	{viewerIsCreator && <Button onClick={()=>editEvent(event)} >Redigera händelse </Button>}

	
	{(!viewerIsCreator && viewerIsCharacterOwner) &&<><div>Godkänd: <Checkbox checked={e.involvedCharacters[viewCharacter.id].approved} onChange={()=>toggleApproved(event)} / ></div></>}
	
	{viewerIsCharacterOwner &&<><div>Visa på Tidslinje: <Checkbox checked={e.involvedCharacters[viewCharacter.id].onTimeline} onChange={()=>toggleTimeline(event)} / ></div></>}
	


	<Button onClick={()=>toggleEvent(event)} >Dölj</Button>
	</div>
	<div className="event-view-characters"><strong>Involverade karaktärer</strong><br />
	{Object.keys(event.involvedCharacters).map(ic => <>
		{event.involvedCharacters[ic].approved && <><div className="approved">{event.involvedCharacters[ic].name.slice(0,40)}</div></>}
		{!event.involvedCharacters[ic].approved && <><div className="not-approved">{event.involvedCharacters[ic].name.slice(0,40)}</div></>}
		</>)}
		
	</div> 
	</div>

	</>
} else if(!isToggled) {

	return <>
	<div className="unToggledEvent" onClick={()=>toggleEvent(event)} >{event.year} - {event.month}: {event.title} </div>

	</>
}

	
}


export default CharacterEvent;