import './AboutViewer.css';

const AboutViewer = () => {



  return (<>

    <div className="about-container">
    <div className="aboutFirst">  
                            <h1>Om</h1>
                            <p>Denna hemsida är framtagen och utvecklad av Gunnar Södergren, i samråd med arrangörsgruppen för Maktspel i Taronne 2.                               </p>
                            <p>Systemet och sidan är fortfarande under mycket utveckling och kan anses vara en alfa-version. Det finns antagligen en hel del buggar och konstigheter som kommer att hittas och tas bort efterhand.</p>
                            <p>Över tid kommer detta system att utvecklas, fler features kommer att läggas till och mer möjligheter öppnas. Vi kommer löpande hålla användarna uppdaterade via Grågripens Discord-server. Frågor om systemet och hur det fungerar ställes med fördel på sagda Discord-server.</p>
                            <h2>Grågripens Discord</h2>
                            <p>Invite: </p>
                            <p>https://discord.gg/7wS3t6x8vK</p>
                            
                            
   
    </div>
    <div className="aboutSecond">
    <h1>FAQ</h1>
                            <h4> Är siten mobilanpassad?</h4>
                            <p>Nej. Inte det minsta. Det kommer senare.</p>
                            <h4> Varför kan jag inte se andras karaktärer? / Varför kan inte andra se min karaktär?</h4>
                            <p>Alla karaktärer måste godkännas av en systemmoderator innan den publiceras. När den är godkänd publiceras den för allmän beskådan.</p>
    
    </div>
    <div className="aboutThird">
    <h1>Stöd projektet</h1>
    <p>Detta system utvecklas av Gunnar Södergren på helt ideell basis, inklusive omkostnader för databas och serverutrymme. Om du önskar stötta projekt, överväg gärna att donera via widgeten nedan. De första 500 SEK varje kalenderår går oavkortat till att hålla servern och databasen igång. Bortom detta går hälften till projektets programmerare (Gunnar Södergren) så att han kan lägga mer tid och energi på att utveckla systemet vidare, och hälften till föreningen Grågripens Monsterjägarskola. </p>
     <iframe id='kofiframe' src='https://ko-fi.com/gunnarsodergren/?hidefeed=true&widget=true&embed=true&preview=true' className={"kofi"} height='712' title='gunnarsodergren'></iframe>
                        
      </div>
    </div>
      
  </>)

  
}


export default AboutViewer;

