//AccountEditor.js
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab} from "semantic-ui-react";
import './App.css';
import * as fb from './firebaseHandler'
import {updateTheStore, useStore} from "./newState";
import {getCopyOf} from "./utilityFunctions";


function changePublicOf(key, account) {

	if(!account[key]) {
		account[key] = {public: false, value:""}
	}

	let pub = !account[key].public

	updateTheStore(s => {s.account[key].public = pub})
}

function changeValueOf(key, newValue, account) {

	if(!account[key]) {
		account[key] = {public: false, value:""}
	}

	account[key].value = newValue
	updateTheStore(s => {s.account = account})
}


function editEmail(newEmail,account) {

	account.email.value = newEmail

	updateTheStore(s => {s.account = account})

}

function editDiscord(newDiscord,account) {

	if(!account.discord) {
		account.discord = {value:"", public: false}
	}

	account.discord.value = newDiscord
  account.discord.public = true

	updateTheStore(s => {s.account = account})

} 

function editPronouns(newPronouns,account) {

	if(!account.pronouns) {
		account.pronouns = {value:"", public: false}
	}

	account.pronouns.value = newPronouns

	updateTheStore(s => {s.account = account})

} 

function editNickname(newNickname,account) {

	if(!account.nickname) {
		account.nickname = {value:"", public: false}
	}

	account.nickname.value = newNickname

	updateTheStore(s => {s.account = account})

} 

async function saveAccount() {

	let testState = useStore.getState()
	let account = getCopyOf(testState.account)
	let profile = getCopyOf(testState.profile)
	await fb.writeAccount(account)

	profile.editing = false

	updateTheStore(s => {s.profile = profile})


}

function goBack() {

	updateTheStore(s => {s.profile.editing = false})

}

const AccountEditor = ({ isOwner = false }) => {

	let testState = useStore.getState()
	let account = getCopyOf(testState.account)

	if(!account || !isOwner) {
		return <></>
	}

	
	return (
		<>
       	<h2>{account.name.value}</h2>'
       	<div className="test">


       		<table>
       		<thead>
       		<tr>
       		<th>Attribut</th>
       		<th>Värde</th>
       		<th className="td-end">Publik?   <span title="Om denna är ikryssad kan andra medlemmar se information när de besöker din profil."><FontAwesomeIcon icon="info-circle" /></span></th>

       		</tr>
       		</thead>
       		<tbody>
        		<tr>
        			<td ><span title="Email-adress"><FontAwesomeIcon className="middle" icon="envelope" size="2x" /> Email</span> </td>
        			<td className="lefty-td"><Input size="small" style={{width: 250}} type='text' value={account.email.value} onChange={e=>editEmail(e.currentTarget.value, account)} /></td>
        			<td className="td-end"><Checkbox checked={account.email.public} onChange={e=>changePublicOf("email", account)} /></td>
        		</tr>
        		<tr>
        			<td><span title="Discord-id"><FontAwesomeIcon className="middle" icon="fa-brands fa-discord" size="2x" />Discord</span></td>
        			<td className="lefty-td"><Input size="small" style={{width: 250}} type='text' value={account.discord.value} onChange={e=>editDiscord(e.currentTarget.value, account)} /></td>
        			<td className="td-end"></td>
        		</tr>
        		<tr>
        			<td><span title="Användarnamn/Smeknamn"><FontAwesomeIcon className="middle" icon="user" size="2x" />Nickname</span></td>
        			<td className="lefty-td"><Input size="small" style={{width: 250}} type='text' value={account.nickname?.value} onChange={e=>editNickname(e.currentTarget.value, account)} /></td>
        			<td className="td-end"><Checkbox checked={account.nickname.public} onChange={e=>changePublicOf("nickname", account)} /></td>
        		</tr>
        		<tr>
        			<td><span title="Användarnamn/Smeknamn">Pronomen</span></td>
        			<td className="lefty-td"><Input size="small" default="Vill inte ange..." style={{width: 250}} type='text' value={account.pronouns?.value} onChange={e=>editPronouns(e.currentTarget.value, account)} /></td>
        			<td className="td-end"><Checkbox checked={account.pronouns?.public} onChange={e=>changePublicOf("pronouns", account)} /></td>
        		</tr>
        		<tr>
        			<td><span title="Namn">Namn</span></td>
        			<td className="lefty-td"><Input size="small" style={{width: 250}} type='text' value={account.name?.value} onChange={e=>changeValueOf("name",e.currentTarget.value, account)} /></td>
        			<td className="td-end"><Checkbox checked={account.name?.public} onChange={e=>changePublicOf("name", account)} /></td>
        		</tr>
        	</tbody>
			</table>
        	
      
        </div>
        	<br />
        	<p><Button onClick={() => saveAccount()}> Spara Profil</Button> </p>

        	<p><Button onClick={() => goBack()}> Tillbaka</Button> </p>
        </>
		)
	
 
};

export default AccountEditor;