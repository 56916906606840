//SocialCard.js

import './Socialcard.css';

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import {updateTheStore, useStore} from "./newState";
import * as fb from './firebaseHandler'
import {loadAllRelevantFromDB, getCopyOf} from './utilityFunctions'

async function chooseCharacter(character) {



  await loadAllRelevantFromDB()


  let characterEvents = {}

  let state = useStore.getState()

  let allEvents = state.characterHandler.allEvents || {}


    for(const id of Object.keys(allEvents)) {
        if( (allEvents[id].involvedCharacters && Object.keys(allEvents[id].involvedCharacters).includes(character.id) )) {
            characterEvents[id] = allEvents[id]
            
        }

    }

   

    let c = getCopyOf(character)
    c.events = characterEvents

    if(!c.categories) {
        c.categories = []
    }
    debugger

	updateTheStore(s => {s.characterHandler.editingACharacter = false})

  updateTheStore(s => {s.characterHandler.toggledCharacterEvents = []})
  

	updateTheStore(s => {s.characterHandler.viewCharacter = c})

	await loadAllRelevantFromDB()

}

export const SocialCard = ({ character}) => {

    let name = character.name.length > 30 ? character.name.slice(0,30) + "..." : character.name
  
    return (
        <div className="card" onClick={()=>chooseCharacter(character)}>
        <p className="card__title">{name}, {character.dead ? "Död" : character.birthYear == 0 ? "Okänt" : 524 - character.birthYear}</p>
            
			<p className="card__image">
                {character.profileThumb && <img src={character.profileThumb}></img>}
            </p>

            <p className="card__body">
            	{character.blurb}

            </p>

        </div>
    )
};

export const CharacterItem = ({character}) => {

	let avatarUrl = character.profileThumb || 'https://react.semantic-ui.com/images/avatar/small/rachel.png'

	return (
		<List.Item onClick={()=>chooseCharacter(character)}>
        	<Image avatar src={avatarUrl} />
      		<List.Content >
        		<List.Header>{character.name}</List.Header>
        		<List.Description>{character.blurb}</List.Description>
      		</List.Content>
    	</List.Item>
	)
}


