//CharacterViewer.js

//AccountViewer.js


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf} from "./utilityFunctions";
import * as constants from './constants'
import {get} from "firebase/database";
import * as ch from './currencyHandler'


function editProfile() {
    let testState = useStore.getState()

    let profile = testState.profile
    updateTheStore(s => {
        s.profile.editing = true
    })

}


function editBusiness(newBusiness = false) {

    updateTheStore(s => {
        s.businessHandler.editingBusiness = true
    })
    if (newBusiness) {
        updateTheStore(s => {
            s.businessHandler.viewBusiness = null
        })

    }
}

function createBusiness() {
  let units = document.getElementById("business_units").value
  let name = document.getElementById("business_name").value

  let testState = useStore.getState()

  let businessHandler = testState.businessHandler

  let eb = getCopyOf(businessHandler.editBusiness)

  eb.units = units
  eb.name = name

  updateTheStore(s => {s.businessHandler.tempBusiness = eb})
  updateTheStore(s => {s.businessHandler.editBusiness = null})


  let startupCosts = bh.getFullStartupCosts(eb)

  let assets = testState.businessHandler.assets

  assets = ch.subtractCurrencies(assets,startupCosts)

  updateTheStore(s => {s.businessHandler.assets = assets})

  updateTheStore(s => {s.businessHandler.editingBusiness = false})
  

}

function chooseTemplate(templateKey) {

    console.log(templateKey)

    if(templateKey === "") {
        return
    }
    let testState = useStore.getState()

    let editBusiness = testState.businessHandler.editBusiness

    if(!editBusiness) {
        editBusiness = getCopyOf(businessTemplates[templateKey])

        editBusiness.businessStarted = constants.CURRENT_EKAYA_DATE
    } else {
        let currentName = ""+editBusiness.name
        editBusiness = getCopyOf(businessTemplates[templateKey])
        editBusiness.name = currentName

    }

    updateTheStore(s => {s.businessHandler.editBusiness = editBusiness})

}

const CreateBusinessView = () => {

    console.log(businessTemplates)

    let testState = useStore.getState()

    let editBusiness = testState.businessHandler.editBusiness

    return <>
        <div> <h1>Skapa ny verksamhet</h1></div>
        <p>Verksamhetens namn: <Input id="business_name" /></p>
        <p>Hur många enheter: <input defaultValue={0} id="business_units"/></p>
        <select onChange={(e) => chooseTemplate(e.currentTarget.value)}>
            <option value={{}}>-</option>
            {Object.keys(businessTemplates).map( key => <option value={key}>{key}</option>)}
        </select>
        <div><Button onClick={() => createBusiness()}>Create Business</Button></div>
        <p>{JSON.stringify(editBusiness)}</p>

    </>

}

const BusinessBox = ({business}) => {

  if(!business) {
    return <></>
  }

  return <>
    <h2>{business.name}</h2>
    <div>Units: {business.units} </div>
    <h3> Revenues</h3>
    <div> {Object.keys(business.revenues).map( r =>
      <div>{r.toUpperCase()}: {business.revenues[r]*100}%</div>
    )}</div>
  
  </>

}

const BusinessViewer = () => {

    

    let testState = useStore.getState()
    let currentUser = testState.auth?.currentUser
    let editingBusiness = testState.businessHandler.editingBusiness

    if(!currentUser) {
        return <><div>Not logged in. </div></>
    }

    let businesses = testState.businessHandler.businesses

    if(!businesses) {
        businesses = {}
        //later get all from database
    }

    let validBusinesses = Object.values(businesses).filter( (b) => {
        if(b.owner === currentUser.uid) {
            return b
        }
    })

    return <><div>
        <select>
            <option value={""}>-</option>
            {validBusinesses.map(b => <option>{b.name}</option> )}
        </select>
        <Button onClick={() => editBusiness(true)} >Skapa ny verksamhet</Button>
        <div>{editingBusiness && <CreateBusinessView />}</div>

        <div><BusinessBox business={testState.businessHandler.tempBusiness} /></div>
        <p>{JSON.stringify(testState.businessHandler.assets)}</p>
        <p>{JSON.stringify(testState.businessHandler.editingBusiness)}</p>

    </div>
    </>


};

export default BusinessViewer;