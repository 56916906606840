//CharacterViewer.js

//AccountViewer.js


import {v4 as uuid} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {SocialCard, CharacterItem} from './SocialCard'
import LoggedInHeader from './LoggedInHeader'

import {useStore, updateTheStore} from './newState'
import CharacterEditor from "./CharacterEditor";
import CharacterEvent from "./CharacterEvent";
import {getCopyOf, loadAllRelevantFromDB, isModerator, isCharacterPublic} from './utilityFunctions'
import * as fb from './firebaseHandler'
import moment from 'moment'

import * as constants from './constants'

function editProfile() {
    let testState = useStore.getState()

    let profile = testState.profile
    updateTheStore(s => {
        s.profile.editing = true
    })

}

function gridOnOff() {

    let ts = useStore.getState()


    let sg = ts.characterViewer.showGrid
    sg = !sg
    updateTheStore(s => {
        s.characterViewer.showGrid = sg
    })

    let testState = useStore.getState()

}

async function changePublicOfCharacter(id) {

  await loadAllRelevantFromDB()

  let testState = useStore.getState()

  let publicCharacters = getCopyOf(testState.characterHandler.publicCharacters)

  let index = -1
  for(let i = 0; i < publicCharacters.length; i++) {
    if(publicCharacters[i] === id) {
      index = i
      break
    }

  }

  if(index > -1) {
    publicCharacters.splice(index,1)
  } else {
    publicCharacters.push(id)
  }

  updateTheStore(s => {
        s.characterHandler.publicCharacters = publicCharacters
    })  

  await fb.writeUtils('publicCharacters', publicCharacters)
}


async function changeParticipationInLarp(larpId, characterId) {

  await loadAllRelevantFromDB()

  let testState = useStore.getState()

  let larp = getCopyOf(testState.larps[larpId])

  if(!larp.characters) {
    larp.characters = []
  }

  if(larp.characters.includes(characterId)) {
    let index = larp.characters.indexOf(characterId)
    larp.characters.splice(index, 1) 
  } else {
    larp.characters.push(characterId)
  }

  
  updateTheStore(s => {
        s.larps[larp.id] = larp
    })  

  await fb.writeLarp(larp)

  await loadAllRelevantFromDB()

}

function npcsOnOff() {

    let ts = useStore.getState()

    let sg = ts.characterViewer.showNPCs
    sg = !sg
    updateTheStore(s => {
        s.characterViewer.showNPCs = sg
    })

    let testState = useStore.getState()

}

function editCharacter(newCharacter = false) {

  let testState = useStore.getState()

  if(!testState.account.discord || !testState.account.discord.public) {
    window.alert("Gå in på Profil och lägg till ditt Discord-konto innan du skapar en karaktär. Om du inte redan är med på föreningens discord, finns inbjudan på fliken Om.")
    return
  }

    updateTheStore(s => {
        s.characterHandler.editingACharacter = true
    })
    if (newCharacter) {
        updateTheStore(s => {
            s.characterHandler.viewCharacter = null
        })

    }
}

function filterCharactersFromDropDown(id) {

  let testState = useStore.getState()

  let dropdown = []

  let larp = testState.larps[id]

  if(larp && larp.characters) {
    for(const charId of larp.characters) {
      dropdown.push(charId)
    }
  } 

  updateTheStore(s => {
        s.characterViewer.filters.dropdown = dropdown
    })

}

function setCategoryFilter(cat) {

  let testState = useStore.getState()


  updateTheStore(s => {
        s.characterViewer.filters.category = cat
    })

}

function setCountryFilter(country) {

  let testState = useStore.getState()


  updateTheStore(s => {
        s.characterViewer.filters.country = country
    })

}


function addNewEventToCharacter(viewCharacter) {

  let vc = getCopyOf(viewCharacter)


  if(!vc.events) {
    vc.events = {}
  }
 
  let id = uuid()
  let creator = viewCharacter.owner
  let involvedCharacters = {}
  let description = ""
  let year = 1
  let month = 1
  let saved = false
  let secret = false
  let title = ""
  let revision = -1
  let deleted = false

  involvedCharacters[viewCharacter.id] = {id:viewCharacter.id, name:viewCharacter.name, approved: true, onTimeline: false}

  vc.events[id] = {id, creator, involvedCharacters, description, revision, year, month, saved, title, secret, deleted}


  updateTheStore(s => {
        s.characterHandler.viewCharacter = vc
    })


}

const CharacterViewer = () => {


    let now = moment()

    let testState = useStore.getState()

    let characters = testState.characterHandler.characters

    if (!characters) {
        characters = {}
    }

    let viewCharacter = getCopyOf(testState.characterHandler.viewCharacter)

    let isEditing = testState.characterHandler.editingACharacter

    let showGrid = testState.characterViewer.showGrid

    let showNPCs = testState.characterViewer.showNPCs

    let filters = testState.characterViewer.filters

    let larps = Object.values(testState.larps)

    let allCategories = constants.CHARCATEGORIES
    let allCountries = constants.ALLCOUNTRIES


    let upcomingLarps = larps.filter((l) => {
        return l.public && now.isBefore(moment(l.endDate))
    })

    let filteredCharacters = {}
    if(showNPCs == false) {
      for(const cId of Object.keys(characters)) {
        if(characters[cId].npc == false) {
          filteredCharacters[cId] = characters[cId]
        }
      } 
    } else {
      filteredCharacters = characters
    }

    if(!testState.account?.isAdmin) {
      filteredCharacters = Object.values(filteredCharacters).filter((c) => {
        return isCharacterPublic(c.id) || c.owner === testState.account?.uid
      })
    }

    if(filters.dropdown.length > 0) {
      filteredCharacters = Object.values(filteredCharacters).filter((c) => {
        return filters.dropdown.includes(c.id)
      })

    }

    if(filters.category && filters.category !== "") {
        filteredCharacters = Object.values(filteredCharacters).filter((c) => {

        if(!c.categories) {
            return false
        }
        return c.categories.includes(filters.category)
      })

    }

    if(filters.country && filters.country !== "") {
        filteredCharacters = Object.values(filteredCharacters).filter((c) => {

        
        return c.origin === filters.country
      })

    }

    let filteredCharactersList = Object.values(filteredCharacters)

    let sortKey = 'name'

    filteredCharactersList.sort(function (a, b) {
            if (!a[sortKey]) {
                return 1
            }
            if (!b[sortKey]) {
                return -1
            }

            let keyA = a[sortKey],
                keyB = b[sortKey]
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })

    let auth = testState.auth

    if (!auth) {
        return <>
            <div>WRONG</div>
        </>
    }

    
    let characterOwnerDiscord
    if(viewCharacter) {
      characterOwnerDiscord = testState.profiles[viewCharacter.owner].discord?.value
      if(!viewCharacter.events) {
        viewCharacter.events = {}
      }
    }

    let characterEventList = viewCharacter?  Object.values(viewCharacter?.events) : []

    let editingAnyEvent = false

    for(const e of characterEventList) {
      if(!e.saved) {
        editingAnyEvent = true
        break
      }
    }

    if(!editingAnyEvent) {
    characterEventList.sort(function (a, b) {

            let aYear = a.year || 0
            let aMonth = a.month || 0


            let bYear = b.year || 0
            let bMonth = b.month || 0

            let keyA = aYear*12+aMonth

            let keyB = bYear*12+bMonth

            if (keyA < keyB) return 1
            if (keyA > keyB) return -1
            return 0
        })
    }


    let userIsOrganiser = testState.larps ? Object.values(testState.larps).filter((l) => {return l.organisers.includes(testState.auth.currentUser.uid)}) : []


    return <>
        <div className="character-container">
        
            <div className="clist-container">
                <div className="viewerMenu">
                    <div className="alignLeft">
                    <h3>Filter</h3>
                     <table> 
                            <tr>
                            <th className="th-filter">Lajv:</th>
                             <td>
                            <div><select onChange={e => filterCharactersFromDropDown(e.currentTarget.value)}> 
                          <option value={""}>-</option>
                          {upcomingLarps.map(l => <option value={l.id}>Anmälda till {l.name}</option> )}
                          
                          </select></div>
                            </td>
                            </tr>
                            <tr>
                             <th className="th-filter">Kategori:</th>
                            <td>
                            <div><select onChange={e => setCategoryFilter(e.currentTarget.value)}> 
                          <option value={""}>-</option>
                          {allCategories.map(c => <option value={c}>{c}</option> )}
                          
                          </select></div>
                            </td>
                            </tr>
                            <tr>
                             <th className="th-filter">Land:</th>
                            <td>
                            <div><select onChange={e => setCountryFilter(e.currentTarget.value)}> 
                          <option value={""}>-</option>
                          {allCountries.map(c => <option value={c}>{c}</option> )}
                          
                          </select></div>
                            </td>
                            </tr>
                          </table>
                    </div>
                    <div className="alignCenter">Grid View: <Checkbox toggle checked={showGrid}
                                                                    onChange={e => gridOnOff()}/><br />
                                               Visa NPCs: <Checkbox toggle checked={showNPCs}
                                                                    onChange={e => npcsOnOff()}/>

                    </div>
                    <div className="alignRight">
                          <Button onClick={() => editCharacter(true)}>Skapa ny karaktär</Button>

                          
                    </div>
                </div>

                <div className="character-list">

                    {showGrid && filteredCharactersList.map((character, index) => (
                        <SocialCard key={index} character={character}/>))}

                    {showGrid === false && <div className="center80">
                        <List divided relaxed>
                            {filteredCharactersList.map((character) => (<> <CharacterItem
                                character={character}/></>))}
                        </List>
                    </div>}
                </div>


            </div>

            <div className="character">
                {!isEditing && <div>

                    {viewCharacter && <div>
                        <p>
                            <div className="lefty">
                                {viewCharacter.profileImage &&
                                    <div><img className="profileImage" src={viewCharacter.profileImage}></img>
                                    </div>}
                            </div>
                            <div className="left-text">
                                <h1>{viewCharacter.name}</h1>
                                {characterOwnerDiscord && <p className={"discordBadge"}>
                                    <FontAwesomeIcon icon="fa-brands fa-discord" size="0.5x" className="middle" />
                                    : {characterOwnerDiscord}
                                    </p>}
                                <h3>Info:</h3>
                                <p className={"characterInfo"}><b>Födelseår:</b> {viewCharacter.birthYear == 0 ? "Okänt": viewCharacter.birthYear}</p>
                                <p className={"characterInfo"}><b>Pronomen:</b> {viewCharacter.pronouns}</p>
                                <p className={"characterInfo"}><b>Härkomst:</b>{viewCharacter.origin}</p>
                                <h3>Kortfattat:</h3>
                                <p>{viewCharacter.blurb}</p>

                            </div>
                        </p>
                        <p>
                        <br />
                            <div className="left-text">
                                <h2>Beskrivning:</h2>
                                <p>{viewCharacter.description}</p>
                            </div>
                        </p>
                        <p>
                        <br />
                            <div className="left-text">
                                <h3>Kategorier</h3>
                                <p>{viewCharacter.categories.join(", ")}</p>
                            </div>
                        </p>
                        <h3>Händelser </h3>
                        <div>{characterEventList.map(e => 
                          <>
                            <CharacterEvent e={e} /> 
                            
                          </>)}
                        </div>
                          <div>{viewCharacter?.owner == auth.currentUser.uid &&
                            <><Button onClick={() => addNewEventToCharacter(viewCharacter)}> Skapa ny händelse </Button></>}
                          </div>
                    </div>

                    }
                    {viewCharacter?.owner == auth.currentUser.uid &&
                        <p className="centry"><Button color='black' onClick={() => editCharacter()}>Redigera
                            Karaktär</Button></p>}
                    {(viewCharacter && userIsOrganiser.length > 0) && <div className="organiserBox"> 
                    <h2>Arrangörsruta</h2>
                    <div> {userIsOrganiser.map(l => 

                    <>{now.isBefore(l.endDate) && <><div><div>Ar anmäld till {l.name}: <Checkbox toggle checked={l.characters?.includes(viewCharacter.id)} onChange={e => changeParticipationInLarp(l.id, viewCharacter.id)}/> </div></div></>}</>

                    )}</div>
                    </div>

                    }

                    {(viewCharacter && isModerator(testState.account)) && <div className="organiserBox"> 
                    <h2>Modruta</h2>
                    <div> 
                      Publik?: <Checkbox toggle checked={isCharacterPublic(viewCharacter.id)} onChange={e => changePublicOfCharacter(viewCharacter.id)}/> 
                    

                    </div>
                    </div>

                    }



                    
                </div>}
                {isEditing && <div>
                    <CharacterEditor character={viewCharacter}/>
                </div>}
            </div>

        </div>

    </>


};

export default CharacterViewer;