//FiefViewer.js


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './FiefViewer.css'
import {SocialCard, CharacterItem} from './SocialCard'
import LoggedInHeader from './LoggedInHeader'
import {LandlotTableView} from './LandlotViewer'

import LandBusinessViewer from './LandBusinessViewer'
import DealViewer from './DealViewer'

import {useStore, updateTheStore} from './newState'
import {
    getCopyOf,
    loadAllRelevantFromDB,
    isModerator,
    isCharacterPublic,
    getDecimalBetween,
    rollDice
} from './utilityFunctions'
import * as fb from './firebaseHandler'
import * as fiefHandler from './fiefHandler'
import * as bh from './businessHandler'

import * as ch from './currencyHandler'
import * as constants from './constants'

import * as fp from './fiefProjectionHandler'
import * as fr from './fiefResultHandler'


function editProfile() {
    let testState = useStore.getState()

    let profile = testState.profile
    updateTheStore(s => {
        s.profile.editing = true
    })

}

function createNewDealTemplate() {

    let buyer = ""
    let seller = ""
    let sold = ch.getCurrencyWithInput({})
    let bought = ch.getCurrencyWithInput({})
    let duty = 0
    let shipping = 0

    let started = {year: 400, month: 0}
    let duration = 12

    return {buyer, seller, sold, bought, duty, shipping, started, duration}

}

function createNewTransaction() {

    let partOne = {fiefId: "", seller: false}
    let partTwo = {fiefId: "", seller: false}
    let price = ch.getCurrencyWithInput({})
    let description = ""


}


async function loadFief(fiefId = null) {


    let state = useStore.getState()


    let allFiefs = await fb.getFiefs()

    updateTheStore(s => {
        s.allFiefs = allFiefs
    })


    let potentiallyMissingArrayKeys = ["employees", "mines", "businesses", "products", "deals"]


    let potentiallyMissingEntityKeys = {
        productionbusiness: ["queue", "currentlyBuilding"]
    }


    let id = fiefId

    if (!id) {

        if (state.account.fiefs && state.account.fiefs.length > 0) {
            id = state.account.fiefs[0]
        } else {
            return
        }
    }

    let newFief = await fb.getFief(id)
    let fiefLogArray = await fb.getLogForFief(id)

    for (const key of potentiallyMissingArrayKeys) {
        if (!newFief[key]) {
            newFief[key] = []
        }
    }

    for (const id of Object.keys(newFief.entities)) {
        let type = id.split("-")[0]
        let missingKeys = potentiallyMissingEntityKeys[type] || []
        for (const key of missingKeys) {
            if (!newFief.entities[id][key]) {
                newFief.entities[id][key] = []
            }
        }
    }


    let fiefLog = {}

    for (const log of fiefLogArray) {
        let key = log.key
        fiefLog[key] = log
        delete fiefLog[key].key
    }
    debugger

    updateTheStore(s => {
        s.newFief = newFief
    })
    updateTheStore(s => {
        s.fiefLog = fiefLog
    })

}


function generateTestFief() {
    let input = constants.TARONNE_FIEFS.nelone
    debugger
    let fiefs = fiefHandler.createFief(input)

    let fief = fiefs.fief
    let newFief = fiefs.newFief

    let testState = useStore.getState()

    let fiefViewer = getCopyOf(testState.fiefViewer)
    let fiefData = getCopyOf(testState.fiefData)

    let baronyMap = {}
    let countyMap = {}
    let estateMap = {}
    let landlotMap = {}

    let estates = fief.estates || []
    let baronies = fief.baronies || []
    let counties = fief.counties || []
    let villages = []
    let landlots = []
    let businesses = fief.businesses || []


    for (const c of counties) {
        countyMap[c.id] = c
        baronies = baronies.concat(c.baronies)
    }

    for (const b of baronies) {
        baronyMap[b.id] = b
        estates = estates.concat(b.estates)
    }

    for (const e of estates) {
        estateMap[e.id] = e
        landlots = landlots.concat(e.landLots)
        villages = villages.concat(e.villages)
    }

    for (const ll of landlots) {
        landlotMap[ll.id] = ll
    }


    fiefViewer.viewBarony = ""
    fiefViewer.viewEstate = ""
    fiefViewer.viewCounty = ""
    fiefViewer.viewLandlot = ""
    fiefViewer.viewportItem = ""
    fiefViewer.toggledLandlot = ""

    fiefData.countyMap = countyMap
    fiefData.baronyMap = baronyMap
    fiefData.estateMap = estateMap
    fiefData.landlotMap = landlotMap


    updateTheStore(s => {
        s.testFief = fief
    })
    updateTheStore(s => {
        s.newFief = newFief
    })
    updateTheStore(s => {
        s.fiefViewer = fiefViewer
    })
    updateTheStore(s => {
        s.fiefData = fiefData
    })
}

function setViewportItem(id) {

    console.log("set view port")
    console.log(id)

    let testState = useStore.getState()
    let viewportItem = testState.fiefViewer.viewportItem
    if (id === "") {
        viewportItem = null
    } else {
        viewportItem = id

    }

    updateTheStore(s => {
        s.fiefViewer.viewportItem = viewportItem
    })

}

function setViewLandlot(lId) {
    let testState = useStore.getState()
    let viewLandlot = testState.fiefViewer.viewLandlot
    if (lId === "") {
        viewLandlot = null
    } else {
        viewLandlot = lId

    }

    updateTheStore(s => {
        s.fiefViewer.viewLandlot = viewLandlot
    })

    setViewportItem(lId)
}

function setViewEstate(eId) {
    let testState = useStore.getState()
    let viewEstate = testState.fiefViewer.viewEstate
    if (eId === "") {
        viewEstate = ""
    } else {
        viewEstate = eId

    }

    updateTheStore(s => {
        s.fiefViewer.viewEstate = viewEstate
    })

    setViewLandlot("")
    setViewportItem(eId)
}

function setViewBarony(bId) {


    let testState = useStore.getState()
    let viewBarony = testState.fiefViewer.viewBarony
    if (bId === "") {
        viewBarony = ""
    } else {
        viewBarony = bId

    }

    updateTheStore(s => {
        s.fiefViewer.viewBarony = viewBarony
    })

    setViewEstate("")
    setViewLandlot("")
    setViewportItem(bId)
}


function setViewCounty(cId) {
    let testState = useStore.getState()
    let viewCounty = testState.fiefViewer.viewCounty
    if (cId === "") {
        viewCounty = ""
    } else {
        viewCounty = cId

    }

    updateTheStore(s => {
        s.fiefViewer.viewCounty = viewCounty
    })

    setViewBarony("")
    setViewEstate("")
    setViewLandlot("")
    setViewportItem(cId)
}


function toggleLandlot(id) {
    let toggled = null
    let testState = useStore.getState()

    let current = testState.fiefViewer.toggledLandlot


    if (current && current === id) {
        toggled = null
    } else {
        toggled = id
    }

    updateTheStore(s => {
        s.fiefViewer.toggledLandlot = toggled
    })

}


function getLandlotProjection(landlot, currentTime) {

    let projection = {}

    let totalRunningCosts = ch.multiplyCurrenciesByFactor(landlot.monthlyCostPerUnit, landlot.units)
    let costInBase = ch.getCurrencyInBase(totalRunningCosts)


    let isActive = currentTime.year * 12 + currentTime.month >= landlot.created.year * 12 + landlot.created.month + landlot.timeBeforeProductive

    let revenueMultiplier = isActive ? costInBase * landlot.quality * constants.HARD_CODED_QUALITY : 0

    let income = ch.multiplyCurrencies(landlot.revenues, ch.conversions.base, revenueMultiplier)

    projection.costs = totalRunningCosts
    projection.incomes = income
    return {projection, result: {}}
}


function prepFiefMonth(newF, log) {


    let newFief = getCopyOf(newF)
    let fiefLog = getCopyOf(log)

    let year = newFief.currentFiefTime.year
    let month = newFief.currentFiefTime.month

    let proj = fp.createFiefProjection(newFief, year, month)

    let logKey = year + "-" + month


    let logEntry = proj
    fiefLog[logKey] = logEntry

    updateTheStore(s => {
        s.proj = proj
    })

    updateTheStore(s => {
        s.newFief = newFief
    })
    updateTheStore(s => {
        s.fiefLog = fiefLog
    })

    return logKey
}

async function runFiefYear() {

    for (let j = 0; j < 1; j++) {
        for (let i = 0; i < 12; i++) {

            let newState = useStore.getState()
            let newFief = getCopyOf(newState.newFief)
            let fiefLog = getCopyOf(newState.fiefLog)

            let logKey = prepFiefMonth(newFief, fiefLog)

            newState = useStore.getState()
            newFief = getCopyOf(newState.newFief)
            fiefLog = getCopyOf(newState.fiefLog)

            runFiefMonth(newFief, fiefLog, logKey)

            console.log(newFief.currentFiefTime)

        }
        let s = useStore.getState()
        let f = s.newFief
        let l = s.fiefLog
        await saveFief(f, l)
    }
    console.log("DONE")

}

async function runFiefMonth(newF, log, logKey) {

    if (!logKey) {
        return
    }

    let newFief = getCopyOf(newF)
    let fiefLog = getCopyOf(log)

    let logEntry = fiefLog[logKey]

    let logParts = logKey.split("-")

    let year = parseInt(logParts[0])
    let month = parseInt(logParts[1])

    let newLogEntry = fr.createFiefResult(newFief, logEntry, year, month)

    let newMonth = month === 12 ? 1 : month + 1
    let newYear = month === 12 ? year + 1 : year

    let fiefEntry = newLogEntry[newFief.id]

    newFief.resources = fiefEntry.balance.newResources
    newFief.loans += fiefEntry.balance.newLoans
    newFief.employees = fiefEntry.events.newEmployeeList
    newFief.mines = fiefEntry.events.newMineList
    newFief.forest += fiefEntry.events.forestGrowth
    newFief.currentFiefTime = {year: newYear, month: newMonth}

    let eventedEntities = Object.keys(newLogEntry).filter((key) => {
        return newLogEntry[key].events != null
    })

    for (const ee of eventedEntities) {
        let type = ee.split("-")[0]

        if (!type || type == "fief") {
            continue
        }

        if (type == "productionbusiness") {
            let events = newLogEntry[ee].events
            let entityCopy = getCopyOf(newFief.entities[ee])

            newFief.products = newFief.products.concat(events.finishedProducts)
            entityCopy.queue = events.newQueue
            entityCopy.addedUnits = events.newAddedUnits
            entityCopy.units = events.newFullUnits
            entityCopy.currentlyBuilding = events.newCurrentlyBuilding

            newFief.entities[ee] = entityCopy

        }
    }


    fiefLog[logKey] = newLogEntry
    if (Object.keys(fiefLog).length > 24) {
        let keys = Object.keys(fiefLog).sort((a, b) => {
            let aValue = parseInt(a.split("-")[0]) * 12 + parseInt(a.split("-")[1])

            let bValue = parseInt(b.split("-")[0]) * 12 + parseInt(b.split("-")[1])

            if (aValue > bValue) {
                return 1
            } else {
                return -1
            }

        })
        let earliestKey = keys[0]
        delete fiefLog[earliestKey]
    }

    updateTheStore(s => {
        s.newFief = newFief
    })
    updateTheStore(s => {
        s.fiefLog = fiefLog
    })


    //updateTheStore(s => {s.currentDate = {year:newYear, month:newMonth}})

}

async function saveFief() {

    let testState = useStore.getState()

    let newFief = getCopyOf(testState.newFief)

    let fiefLog = getCopyOf(testState.fiefLog)


    let logArray = []
    for (const key of Object.keys(fiefLog)) {


        let logForArr = getCopyOf(fiefLog[key])
        logForArr.key = key
        logArray.push(logForArr)
    }

    await fb.writeFief(newFief, logArray)


    updateTheStore(s => {
        s.newFief = newFief
    })

}


const LandlotSummary = ({newFief, lastLog}) => {

    let summaryProjections = {}

    for (const id of Object.keys(newFief.entities)) {

        let type = id.split("-")[0]
        let entity = newFief.entities[id]

        if (type === "landlot") {
            if (!summaryProjections[entity.product]) {
                summaryProjections[entity.product] = {
                    hectares: 0,
                    projectedCosts: ch.getCurrencyWithInput({}),
                    projectedIncomes: ch.getCurrencyWithInput({}),
                    count: 0, combinedQuality: 0
                }
            }
            summaryProjections[entity.product].hectares += entity.units
            summaryProjections[entity.product].count++
            summaryProjections[entity.product].combinedQuality += entity.quality
            summaryProjections[entity.product].projectedCosts = ch.addCurrencies(summaryProjections[entity.product].projectedCosts, lastLog[id].projection.costs.total)
            summaryProjections[entity.product].projectedIncomes = ch.addCurrencies(summaryProjections[entity.product].projectedIncomes, lastLog[id].projection.incomes.total)
        }
    }

    let arrayOfArrays = []

    let keys = Object.keys(summaryProjections)
    while (keys.length > 0) {

        arrayOfArrays.push(keys.slice(0, 2))
        keys = keys.slice(2, keys.length)

    }


    return <>

        <table className={"tableOuter"}>
            <tr className={"tableOuter"}>
                <th><strong>Produkt</strong></th>
                <th><strong>Antal</strong></th>
                <th><strong>Hektar</strong></th>
                <th><strong>Kvalitet</strong></th>
                <th><strong>Inkomst/Utgift</strong></th>
            </tr>
            {Object.keys(summaryProjections).map(key =>
                <tr className={"tableOuter"}>
                    <td><strong>{key}</strong></td>
                    <td>{summaryProjections[key].count}</td>
                    <td>{summaryProjections[key].hectares}</td>
                    <td>{(Math.round((summaryProjections[key].combinedQuality / summaryProjections[key].count) * 100) / 100)}</td>
                    <td>
                        <table className={"tableInner"}>
                            <tr>
                                <th></th>
                                {constants.CURRENCY_ORDER.map(currencyKey =>
                                    <th> {constants.SWEDISH_CURRENCY_NAMES[currencyKey]} </th>)}
                            </tr>
                            <tr className={"slightlyGreen"}>
                                <td><strong>Inkomst</strong></td>
                                {constants.CURRENCY_ORDER.map(currencyKey =>
                                    <td> {Math.round(summaryProjections[key].projectedIncomes[currencyKey]).toLocaleString('sv-SE') || 0} </td>)}
                            </tr>
                            <tr className={"slightlyRed"}>
                                <td><strong>Utgift</strong></td>
                                {constants.CURRENCY_ORDER.map(currencyKey =>
                                    <td> {Math.round(summaryProjections[key].projectedCosts[currencyKey]).toLocaleString('sv-SE') || 0} </td>)}
                            </tr>

                        </table>
                    </td>

                </tr>)}
        </table>

    </>

}

const BusinessSummary = ({newFief, lastLog}) => {


    let summaryProjections = {}

    for (const id of Object.keys(newFief.entities)) {

        let type = id.split("-")[0]
        let entity = newFief.entities[id]

        if (type === "business") {

            if (!lastLog[id]) {
                continue
            }

            if (!summaryProjections[entity.name]) {
                summaryProjections[entity.name] = {
                    size: 0,
                    projectedCosts: ch.getCurrencyWithInput({}),
                    projectedIncomes: ch.getCurrencyWithInput({}),
                    count: 0, combinedQuality: 0,
                    unitName: entity.unitName || "enheter"
                }
            }
            summaryProjections[entity.name].size += entity.units
            summaryProjections[entity.name].count++
            summaryProjections[entity.name].combinedQuality += entity.quality
            summaryProjections[entity.name].projectedCosts = ch.addCurrencies(summaryProjections[entity.name].projectedCosts, lastLog[id].projection.costs.total)
            summaryProjections[entity.name].projectedIncomes = ch.addCurrencies(summaryProjections[entity.name].projectedIncomes, lastLog[id].projection.incomes.total)
        }
    }

    let arrayOfArrays = []

    let keys = Object.keys(summaryProjections)
    while (keys.length > 0) {

        arrayOfArrays.push(keys.slice(0, 5))
        keys = keys.slice(5, keys.length)

    }

    debugger
    return <>

        <table>
            {arrayOfArrays.map(arr => <>
                <tr>
                    {arr.map(key => <>
                        <td>
                            <strong>{key}</strong><br/>
                            Planerad inkomst:
                            {Object.keys(summaryProjections[key].projectedIncomes).map(piKey =>
                                <>
                                    {summaryProjections[key].projectedIncomes[piKey] > 0 &&
                                        <> {constants.SWEDISH_CURRENCY_NAMES[piKey]}: {Math.round(summaryProjections[key].projectedIncomes[piKey]).toLocaleString('sv-SE')},</>
                                    }

                                </>
                            )}<br/>

                            Planerad kostnad:
                            {Object.keys(summaryProjections[key].projectedCosts).map(piKey =>
                                <>
                                    {summaryProjections[key].projectedCosts[piKey] > 0 &&
                                        <> {constants.SWEDISH_CURRENCY_NAMES[piKey]}: {Math.round(summaryProjections[key].projectedCosts[piKey]).toLocaleString('sv-SE')},</>
                                    }

                                </>
                            )}
                            <br/>
                            Storlek: {summaryProjections[key].size} {summaryProjections[key].unitName} <br/>
                            Kvalitet: {(Math.round((summaryProjections[key].combinedQuality / summaryProjections[key].count) * 100) / 100).toLocaleString('sv-SE')}
                        </td>

                    </>)}

                </tr>
            </>)}
        </table>
    </>

}

const GarrisonSummary = ({garrison}) => {


    let garrisonSummary = {}

    for (const key of Object.keys(garrison)) {
        if (!garrisonSummary[key]) {
            console.log(key)
            console.log(constants.SOLDIERS[key])
            let cost = ch.getCurrencyWithInput(constants.SOLDIERS[key])
            garrisonSummary[key] = {
                count: garrison[key],
                name: constants.SWEDISH_SOLDIER_NAMES[key] || key,
                costs: ch.multiplyCurrenciesByFactor(cost, garrison[key])
            }
        }
    }
    let garrisonCostKeys = ['base', 'lux', 'money', 'labours']

    return <>

        <table>
            <tr>
                <th>Typ</th>
                <th>Antal</th>
                <th>Kostnad</th>
            </tr>
            {Object.values(garrisonSummary).map(gs =>
            <tr>
                <td>{gs.name}</td>
                <td>{gs.count}</td>
                <td>
                    <table className={"tableInner"}>
                        <tr>

                            {garrisonCostKeys.map(currencyKey =>
                                <th> {constants.SWEDISH_CURRENCY_NAMES[currencyKey]} </th>)}
                        </tr>

                        <tr className={"slightlyRed"}>
                            {garrisonCostKeys.map(currencyKey =>
                                <td> {Math.round(gs.costs[currencyKey]).toLocaleString('sv-SE') || 0} </td>)}
                        </tr>

                    </table>
                </td>
            </tr>

            )}

        </table>

    </>

}

const ProductSummary = ({products}) => {


    return <>

        {products.map(p => <>

            {p.type} < br/>
        </>)}

    </>

}

const MineOVerview = ({newFief, lastLog}) => {

    let year = newFief.currentFiefTime.year
    let month = newFief.currentFiefTime.month

    let mines = newFief.mines.filter((m) => {
        return year * 12 + month > m.created.year * 12 + m.created.month &&
            year * 12 + month < m.created.year * 12 + m.created.month + m.timeLeft

    })


    let arrayOfArrays = []

    let minesCopy = [...mines]
    while (minesCopy.length > 0) {

        arrayOfArrays.push(minesCopy.slice(0, 2))
        minesCopy = minesCopy.slice(2, minesCopy.length)

    }

    return <>
        <table>

            {arrayOfArrays.map(arr => <>
                <tr>

                    {arr.map(m => <>
                        <td>

                            <strong>{m.type}</strong> <br/>
                            Inkomst: {(m.revenue * m.units * newFief.baseQualities.ore).toLocaleString('sv-SE')}<br/>
                        </td>
                    </>)}

                </tr>
            </>)}
        </table>


    </>
}

const ForestOverview = ({newFief, lastLog}) => {

    if (!newFief.employees || !lastLog) {
        return <></>
    }

    let hunters = newFief.employees.filter((e) => {
        return e === "hunter"
    })

    let forestIncome = lastLog[newFief.id].projection.incomes.forest
    let hunterIncome = lastLog[newFief.id].projection.incomes.hunting


    let forestCost = lastLog[newFief.id].projection.costs.forest
    let hunterCost = ch.multiplyCurrenciesByFactor(constants.EMPLOYEES.hunter, hunters.length)

    return <>
        <div>
        </div>

        <table>
            <tr>
                <td>
                    <h3>Skogsavverkning</h3>
                </td>
                <td>
                    <h3>Jakt</h3>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        Antal hektar huggna: {forestCost.labours / 140} <br/>
                        Inkomst:

                        {Object.keys(forestIncome).map(key => <>

                            {forestIncome[key] > 0 && <> {constants.SWEDISH_CURRENCY_NAMES[key]}: {forestIncome[key]}, </>}

                        </>)} <br/>
                        Kostnad:
                        {Object.keys(forestCost).map(key => <>

                            {forestCost[key] > 0 && <> {constants.SWEDISH_CURRENCY_NAMES[key]}: {forestCost[key]}, </>}

                        </>)}

                    </div>

                </td>

                <td>
                    <div>

                        Antal Jägare: {hunters.length} <br/>
                        Inkomst:

                        {Object.keys(hunterIncome).map(key => <>

                            {hunterIncome[key] > 0 && <> {constants.SWEDISH_CURRENCY_NAMES[key]}: {hunterIncome[key]}, </>}

                        </>)} <br/>
                        Kostnad:
                        {Object.keys(hunterCost).map(key => <>

                            {hunterCost[key] > 0 && <> {constants.SWEDISH_CURRENCY_NAMES[key]}: {hunterCost[key]}, </>}

                        </>)}

                    </div>
                </td>
            </tr>
        </table>


    </>
}


const FiefOverview = ({newFief, fiefLog}) => {


    let estates = newFief.estates || []
    let baronies = newFief.baronies || []
    let counties = newFief.counties || []
    let villages = newFief.villages || []
    let landlots = newFief.landlots || []
    let businesses = newFief.businesses || []

    let resources = newFief.resources
    let loans = newFief.loans

    let keys = Object.keys(fiefLog || {})
    keys.sort((a, b) => {
        let aValue = parseInt(a.split("-")[0]) * 12 + parseInt(a.split("-")[1])

        let bValue = parseInt(b.split("-")[0]) * 12 + parseInt(b.split("-")[1])

        if (aValue > bValue) {
            return 1
        } else {
            return -1
        }

    })
    let lastKey
    let lastKeyHasResult = false
    if (keys.length > 0) {
        lastKey = keys[keys.length - 1]

        let firstLogItem = Object.values(fiefLog[lastKey])[0]

        if (firstLogItem.results) {
            lastKeyHasResult = true
        }

    }


    let summaryProjection = {
        costs: ch.getCurrencyWithInput({}),
        incomes: ch.getCurrencyWithInput({})
    }
    if (lastKey) {
        let log = fiefLog[lastKey]

        for (const key of Object.keys(log)) {
            let val = log[key]
            if (!val.projection.costs.total) {
            }
            summaryProjection.costs = ch.addCurrencies(summaryProjection.costs, val.projection.costs.total)
            summaryProjection.incomes = ch.addCurrencies(summaryProjection.incomes, val.projection.incomes.total)
        }
    }

    return <>

        <div className={"fief-container"}>
            <div className={"garrison"}>
                <h2>Garnison/Armé</h2>
                {newFief.garrison && <>
                    <GarrisonSummary garrison={newFief.garrison}/></>}

            </div>
            <div className={"resources"}>

                <h2>Resurser</h2>
                {resources && <>
                    <table>
                        <tr>
                            <th></th>
                            {constants.CURRENCY_ORDER.map(key => <th> {constants.SWEDISH_CURRENCY_NAMES[key]} </th>)}
                        </tr>
                        <tr>
                            <td><strong>Tillgångar</strong></td>
                            {constants.CURRENCY_ORDER.map(key =>
                                <td> {Math.round(resources[key]).toLocaleString('sv-SE')} </td>)}
                        </tr>

                    </table>
                </>}


                <h2>Månatliga Inkomster och Utgifter</h2>
                {lastKey && <>
                    <table>
                        <tr>
                            <th></th>
                            {constants.CURRENCY_ORDER.map(key => <th> {constants.SWEDISH_CURRENCY_NAMES[key]} </th>)}
                        </tr>
                        <tr>
                            <td><strong>Inkomster</strong></td>
                            {constants.CURRENCY_ORDER.map(key =>
                                <td> {Math.round(summaryProjection.incomes[key]).toLocaleString('sv-SE')} </td>)}
                        </tr>
                        <tr>
                            <td><strong>Utgifter</strong></td>
                            {constants.CURRENCY_ORDER.map(key =>
                                <td> {Math.round(summaryProjection.costs[key]).toLocaleString('sv-SE')} </td>)}
                        </tr>

                    </table>
                </>}
            </div>
            <div className={"info"}>
                <h2>Länets sammansättning</h2>


                {counties.length > 0 && <div> Grevskap: {counties.length} <br/></div>}
                {baronies.length > 0 && <div> Baronier: {baronies.length}<br/></div>}
                {estates.length > 0 && <div> Gods: {estates.length}<br/></div>}
                {villages.length > 0 && <div> Byar: {villages.length}<br/></div>}
                {landlots.length > 0 && <div> Markverksamheter: {landlots.length}<br/></div>}
                {businesses.length > 0 && <div> Affärsversamheter: {businesses.length}<br/></div>}
                {newFief.forest > 0 &&
                    <div> Skog, hektar: {Math.round(newFief.forest).toLocaleString('sv-SE')}<br/></div>}
                Lån (örnar): {newFief.loans}


            </div>

            <div className={"landlots"}>
                <h2>Markverksamheter, översikt</h2>
                {lastKey && <>
                    <LandlotSummary newFief={newFief} lastLog={fiefLog[lastKey]}/>
                </>}
            </div>

            <div className={"businesses"}>
                <h2>Affärsverksamheter</h2>
                {lastKey && <>
                    <BusinessSummary newFief={newFief} lastLog={fiefLog[lastKey]}/>
                </>}


                {(newFief.products && newFief.products.length > 0) && <><h2>Produkter i Lager</h2>
                    <ProductSummary products={newFief.products}/></>}
                <br/>
                {(newFief.mines && newFief.mines.length > 0) && <> <h2>Gruvor</h2>
                    <MineOVerview newFief={newFief} lastLog={fiefLog[lastKey]}/></>}

                <h2>Skogsverksamhet</h2>
                <ForestOverview newFief={newFief} lastLog={fiefLog[lastKey]}/>
                <br/>
                {lastKey && <> <CostOverview fief={newFief} fiefLog={fiefLog}  />></>}
            </div>
        </div>
    </>

}

const EstateView = ({id}) => {


    let testState = useStore.getState()

    let newFief = testState.newFief

    let estate = newFief.entities[id]

    return <>
        <div>{id}</div>
        <br/>
        <div>Byar: {estate.villages.length}</div>
        <div>Markverksamheter: {estate.landlots.length}</div>
        <div><LandlotTableView landlotIds={estate.landlots}/></div>
        <div>Brukbar mark: {estate.totalUsableLand}</div>


    </>

}

const BaronyView = ({id}) => {

    let testState = useStore.getState()

    let newFief = testState.newFief


    let barony = newFief.entities[id]


    return <>
        <div>{id}</div>
        <div><LandlotTableView landlotIds={barony.landlots}/></div>

    </>


}

const LandlotViewPort = ({id}) => {

    return <>
        <div>{id}</div>
    </>
}

const CountyView = ({id}) => {

    let testState = useStore.getState()

    let newFief = testState.newFief


    let county = newFief.entities[id]


    return <>
        <div>{id}</div>
        <div><LandlotTableView landlotIds={county.landlots}/></div>

    </>
}

const CostOverview = ({fief, fiefLog}) => {

    let keys = Object.keys(fiefLog || {})
    keys.sort((a, b) => {
        let aValue = parseInt(a.split("-")[0]) * 12 + parseInt(a.split("-")[1])

        let bValue = parseInt(b.split("-")[0]) * 12 + parseInt(b.split("-")[1])

        if (aValue > bValue) {
            return 1
        } else {
            return -1
        }

    })

    let lastKey
    let lastKeyHasResult = false
    if (keys.length > 0) {
        lastKey = keys[keys.length - 1]

        let firstLogItem = Object.values(fiefLog[lastKey])[0]

        if (firstLogItem.results) {
            lastKeyHasResult = true
        }

    }
    let incomeTypes = {}
    let costTypes = {}
    let types = {}
    if (lastKey) {
        let log = fiefLog[lastKey]

        for (const key of Object.keys(log)) {

            if(key == "key") {
                continue
            }

            let type = key.split("-")[0]

            if(!costTypes[type]) {
                costTypes[type] = {}
            }

            if(!incomeTypes[type]) {
                incomeTypes[type] = {}
            }

            if(!types[type]) {
                types[type] = {incomes:{}, costs:{}}
            }

            let val = log[key]

            for(const costKey of Object.keys(val.projection.costs)) {
                if(costKey == "total") {
                    continue
                }
                if(!costTypes[type][costKey]) {
                    costTypes[type][costKey] = ch.getCurrencyWithInput({})
                }

                if(!types[type].costs[costKey]) {
                    types[type].costs[costKey] = ch.getCurrencyWithInput({})
                }

                types[type].costs[costKey] = ch.addCurrencies(costTypes[type][costKey], val.projection.costs[costKey])
                costTypes[type][costKey] = ch.addCurrencies(costTypes[type][costKey], val.projection.costs[costKey])
            }
            for(const costKey of Object.keys(val.projection.incomes)) {
                if(costKey == "total") {
                    continue
                }
                if(!incomeTypes[type][costKey]) {
                    incomeTypes[type][costKey] = ch.getCurrencyWithInput({})
                }

                if(!types[type].incomes[costKey]) {
                    types[type].incomes[costKey] = ch.getCurrencyWithInput({})
                }

                incomeTypes[type][costKey] = ch.addCurrencies(incomeTypes[type][costKey], val.projection.incomes[costKey])
                types[type].incomes[costKey] = ch.addCurrencies(types[type].incomes[costKey], val.projection.incomes[costKey])
            }
        }
    }

    return <>

        {}

        <div>{JSON.stringify(types)}</div>
    </>
}

const ViewPort = ({id, year, month}) => {


    console.log("Viewport id:")
    console.log(id)
    let type = id.split("-")[0]
    if (!id) {
        return <></>
    }

    return <>
        {type === "landlot" && <LandlotViewPort id={id}/>}
        {type === "estate" && <EstateView id={id}/>}
        {type === "barony" && <BaronyView id={id} year={year} month={month}/>}
        {type === "county" && <CountyView id={id}/>}
    </>


}

export const FiefPrintViewer = () => {

    return <>
        <div>This will be the print view</div>
    </>
}


export const FiefViewer = () => {

    let testState = useStore.getState()

    let newFief = testState.newFief
    let fiefLog = testState.fiefLog

    let fiefViewer = testState.fiefViewer

    let year = testState.currentDate.year
    let month = testState.currentDate.month

    let filteredCounties = newFief.counties || []
    let filteredBaronies = newFief.baronies || []
    let filteredEstates = newFief.estates || []
    let filteredLandlots = newFief.landLots

    if (fiefViewer.viewCounty !== "") {
        filteredBaronies = newFief.entities[fiefViewer.viewCounty].baronies
        filteredEstates = newFief.entities[fiefViewer.viewCounty].estates
        filteredLandlots = newFief.entities[fiefViewer.viewCounty].landlots
    }
    if (fiefViewer.viewBarony !== "") {
        filteredEstates = newFief.entities[fiefViewer.viewBarony].estates
        filteredLandlots = newFief.entities[fiefViewer.viewBarony].landlots
    }
    if (fiefViewer.viewEstate !== "") {
        filteredLandlots = newFief.entities[fiefViewer.viewEstate].landlots
    }

    let keys = Object.keys(fiefLog || {})
    keys.sort((a, b) => {
        let aValue = parseInt(a.split("-")[0]) * 12 + parseInt(a.split("-")[1])

        let bValue = parseInt(b.split("-")[0]) * 12 + parseInt(b.split("-")[1])

        if (aValue > bValue) {
            return 1
        } else {
            return -1
        }

    })

    let lastKey
    let lastKeyHasResult = true
    if (keys.length > 0) {
        lastKey = keys[keys.length - 1]

        let firstLogItem = Object.values(fiefLog[lastKey])[0]

        if (!firstLogItem.results) {
            lastKeyHasResult = false
        }

    }


    return <>
        <div>{fiefLog && <>{Object.keys(fiefLog).length} </>}</div>
        {testState?.account?.isAdmin && <>
            <button onClick={() => generateTestFief()}>Generera Baroni</button>

            <button onClick={() => prepFiefMonth(newFief, fiefLog)}>Skapa månad</button>

            <button disabled={lastKeyHasResult} onClick={() => runFiefMonth(newFief, fiefLog, lastKey)}>Kör månad
            </button>

            <button onClick={() => runFiefYear()}>Kör ett år</button>

            <button onClick={() => saveFief()}>Spara Län</button>
            <br/>
            <select onChange={e => loadFief(e.currentTarget.value)}>
                <option value={""}>-</option>
                {Object.keys(testState.allFiefs).map(fId => <option
                    value={fId}>{testState.allFiefs[fId].name}</option>)}

            </select>
            <br/>
            <DealViewer/>
        </>}
        <button onClick={() => loadFief()}>Ladda mitt län</button>


        {newFief.name !== null && <>

            <h1>{newFief.name}</h1>
            <div>ID: {newFief.id}</div>
            <FiefOverview newFief={newFief} fiefLog={fiefLog}/>
        </>}
    </>


    return <>

        {filteredCounties.length > 0 && <>
            <div>Grevskap:</div>
            <select onChange={e => setViewCounty(e.currentTarget.value)}>
                <option value={""}>-</option>
                {filteredCounties.map(c => <option value={c}>{newFief.entities[c].name}</option>)}

            </select>
        </>}
        {filteredBaronies.length > 0 && <>
            <div>Baronier:</div>
            <select value={fiefViewer.viewBarony} onChange={e => setViewBarony(e.currentTarget.value)}>
                <option value={""}>-</option>
                {filteredBaronies.map(b => <option value={b}>{newFief.entities[b].name}</option>)}

            </select>
        </>}
        {filteredEstates.length > 0 && <>
            <div>Gods:</div>
            <select value={fiefViewer.viewEstate} onChange={e => setViewEstate(e.currentTarget.value)}>
                <option value={""}>-</option>
                {filteredEstates.map(est => <option value={est}>{newFief.entities[est].name}</option>)}

            </select>
        </>}

    </>


};