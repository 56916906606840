import create from 'zustand'
import {produce} from 'immer'

export const useStore = create((set) => ({
    fiefPrintView: false,
    moderators: [],
    auth: null,
    storage: null,
    account: null,
    year: 400,
    month: 1,
    larps: {},
    assets: {base: 0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
    profiles: {},
    adminHandler: {
      creatingLarp: false,
      larpOrganisers: []
    },
    businessHandler: {
        businesses: {},
        editingBusiness: false,
        viewBusiness: null,
        editBusiness: null, 
        tempBusiness: null,
        assets: {base: 0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0}
    },
    profile: {editing: false, editingLarpInfo: false},
    characterHandler: {
        characters: {},
        filteredCharacters: [],
        viewCharacter: null,
        editingACharacter: false,
        uploadedFile: null,
        uploadedThumb: null,
        percent: 0,
        uploadedImageUrl: "",
        uploadedThumbUrl: "",
        publicCharacters: [],
        toggledCharacterEvents: [],
        allEvents: {}
    },
    characterViewer: {
        filters: {
            search: null,
            dropdown: [],
            category: "",
            country:""

        },
        showGrid: true,
        showNPCs: false
    },
    organiserHandler: {
        chosenLarp: ""
    },
    larpViewerhandler: {
        chosenLarp: ""
    },
    testFief: {},
    newFief: {},
    fiefLog: {},
    testMonth: {},
    monthResult: {},
    currentDate: {year: 400, month: 1},
    fiefData: {
      countyMap: {},
      baronyMap: {},
      estateMap:{},
      landlotMap:{}

    },
    fiefViewer: {
      viewCounty: "",
      viewBarony: "",
      viewEstate: "", 
      viewLandlot: "",
      viewportItem: "",
      toggledLandLot: ""
    },
    deal: {}, 
    allFiefs: {}
}));


export function updateTheStore(setter) {
    useStore.setState(realState => produce(realState, (immerState) => {
        setter(immerState)
    }))
}

