//OrganiserViewer.js

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf, loadAllRelevantFromDB} from "./utilityFunctions";
import * as constants from './constants'
import * as ch from './currencyHandler'
import {v4 as uuid} from 'uuid';
import moment from 'moment'
import * as fb from './firebaseHandler'

import CryptoJS from 'react-native-crypto-js';


const enc = "ZSOFusafUaB6xCfloF+2WTMtGo1FDRsI2Ni7pfXy6DnS3eUSoiyDKhA4pKNMzvPa"

const questionTypes = ["Kort Text", "Lång Text", "Checkbox", "Välj en", "Välj Flera"]

const FORM_TEMPLATE = {questions: {}, 
					   description: "", 
					   afterSubmissionNote: "",
					   endNote:"", 
					   openForSubmissions: false}


async function chooseLarp(larpId) {


	if(larpId === "-") {
		return
	}

	updateTheStore(s => {
        s.organiserHandler.chosenLarp = larpId
    })


}

function BasicInfo() {
	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp

	

	if(chosenLarp == "") {
		return <></>
	}

	let larp = testState.larps[chosenLarp]

	return <>

		<p>Namn: {larp.name}</p>
		<p>Plats: {larp.location}</p>
		<p>Start: {larp.startDate}</p>
		<p>Slut: {larp.endDate}</p>


	</>


}

async function addQuestionToParticipantForm() {



	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp


	if(chosenLarp == "") {
		return <></>
	}

	let larp = getCopyOf(testState.larps[chosenLarp])


	let participantForm = larp.participantForm

	if(!participantForm) {

		participantForm = getCopyOf(FORM_TEMPLATE)

	}
	let id = uuid()
	let q = {id, text: "", type: "", saved:false, mandatory: false, options: []}

	participantForm.questions[id] = q

	larp.participantForm = participantForm

	updateTheStore(s => {
        s.larps[chosenLarp] = larp
    })

}

async function saveQuestion(questionId) {

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp
	let larp = getCopyOf(testState.larps[chosenLarp])

	let question = getCopyOf(larp.participantForm.questions[questionId])

	question.saved = true

	let text = document.getElementById(questionId + "_text")?.value || ""

	question.text = text

	larp.participantForm.questions[questionId] = question
	
	updateTheStore(s => {
        s.larps[chosenLarp] = larp
    })

    await fb.writeLarp(larp)
}

async function deleteQuestion(questionId) {

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp
	let larp = getCopyOf(testState.larps[chosenLarp])

	let questions = getCopyOf(larp.participantForm.questions)

	delete questions[questionId]


	larp.participantForm.questions = questions
	
	updateTheStore(s => {
        s.larps[chosenLarp].participantForm.questions = questions
    })

    await fb.writeLarp(larp)
}

function deleteOptionToQuestion(questionId, option) {

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp
	let larp = testState.larps[chosenLarp]

	let question = getCopyOf(larp.participantForm.questions[questionId])

	if(question.options.includes(option)) {
		const index = question.options.indexOf(option);
		if (index > -1) { // only splice array when item is found
  			question.options.splice(index, 1); // 2nd parameter means remove one item only
		}
	} 
	
	updateTheStore(s => {
        s.larps[chosenLarp].participantForm.questions[questionId] = question
    })

} 

function addOptionToQuestion(questionId) {

	let val =  document.getElementById(questionId + "_options").value 


	if(!val || val === "") {
		return
	}

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp
	let larp = testState.larps[chosenLarp]

	let question = getCopyOf(larp.participantForm.questions[questionId])

	if(!question.options.includes(val)) {
		question.options.push(val)
		document.getElementById(questionId + "_options").value = ""
	} 
	
	updateTheStore(s => {
        s.larps[chosenLarp].participantForm.questions[questionId] = question
    })

}

function setValOfQuestionKey(val, key, questionId) {

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp
	let larp = testState.larps[chosenLarp]
	let question = getCopyOf(larp.participantForm.questions[questionId])
	if(key === "type") {
		question[key] = val
	} else if( key === "mandatory") {
		question[key] = !question[key]
	} else if (key === "saved") {
		question[key] = val
	}

	updateTheStore(s => {
        s.larps[chosenLarp].participantForm.questions[questionId] = question
    })

}

function QuestionCreator({question}) {

	if(question.saved) {
		return <></>
	}

	let type = document.getElementById(question.id + "_type")?.value 


	return <>
	Fråga: <input defaultValue={question.text} id={question.id + "_text"}/>
	Typ: <select onChange={e => setValOfQuestionKey(e.currentTarget.value, "type", question.id)} defaultValue={question.type} id={question.id+ "_type"}>
                            <option value="">-</option>
                            {questionTypes.map(c => <option value={c}>{c}</option>)}
                        </select>

    {["Välj en", "Välj Flera"].includes(question.type) && 
    <div>Alternativ: <input defaultValue="" id={question.id + "_options"}/> 
    <Button size="small" onClick={() => addOptionToQuestion(question.id)}>Lägg till Alternativ</Button> 

    {question.options.map(o => <><div>{o} <Button color='red' size='mini' onClick={() => deleteOptionToQuestion(question.id, o)}>X</Button> </div></>)}
    </div>}
    Obligatorisk: <Checkbox checked={question.mandatory} onChange={e => setValOfQuestionKey("", "mandatory", question.id)}/>

    <Button onClick={() => saveQuestion(question.id)}>Spara fråga</Button> 
    <Button color='red' onClick={() => deleteQuestion(question.id)}>Ta bort fråga</Button> 
	</>
    

}

function QuestionViewer({question}) {


	return <>
		<h4>{question.text}</h4>
		<div><strong>Typ: </strong>{question.type}</div>
		<div><strong>Obligatorisk: </strong>{question.mandatory? "Ja": "Nej"}</div>
		{question.options?.length > 0 && <><div><strong>Alternativ: </strong>{question.options?.join(", ")}</div></>}


    	<Button size='mini' onClick={() => setValOfQuestionKey(false, "saved", question.id)}>Redigera Fråga</Button> 


	</>

}

function ParticipantSubmissionsViewer() {

	let testState = useStore.getState()
	let larp = testState.larps[testState.organiserHandler.chosenLarp]

	let submissions = larp.participantForm.submissions
	let questionKeysSorted = Object.keys(larp.participantForm.questions).sort()
	let questions = larp.participantForm.questions

	let profiles = testState.profiles

	let personalInfoKeysSorted = Object.keys(constants.PERSONAL_INFO_MAPPER)


	let decryptedPersonalInfo = {}

	for(const sub of Object.keys(submissions)) {
		let personalInfo = profiles[sub].personal

		let cryptKey = enc + personalInfo.id

		let decrypted = {}

		for(const key of Object.keys(personalInfo)) {

            let newVal = CryptoJS.AES.decrypt(personalInfo[key], cryptKey).toString(CryptoJS.enc.Utf8)
            decrypted[key] = newVal

		}
		decryptedPersonalInfo[sub] = decrypted
	}


	return <>

		<h3>Personlig Information</h3>

		<table>
			<tr>
			<th>Namn</th>
			<th>Email</th>
			{personalInfoKeysSorted.map((key) => <>

				<th>
					{constants.PERSONAL_INFO_MAPPER[key]}
				</th>

			</>)}
			</tr>
			{Object.keys(submissions).map((sKey) => <>
				<tr>
					<td>{profiles[sKey].name.value}</td>
					<td>{profiles[sKey].email.value}</td>
					{personalInfoKeysSorted.map((piKey) => <>
						<td>
							{decryptedPersonalInfo[sKey][piKey]}
						</td>
						</>)}

				</tr>

				</>)  }

		</table>
		<h3>Formulärsvar</h3>

		<table>
			<tr>
			<th>Namn</th>
			<th>Email</th>
			{questionKeysSorted.map((key) => <>

				<th>
					{questions[key].text}
				</th>

			</>)}
			</tr>
			{Object.keys(submissions).map((sKey) => <>
				<tr>
					<td>{profiles[sKey].name.value}</td>
					<td>{profiles[sKey].email.value}</td>
					{questionKeysSorted.map((qKey) => <>
							<td>{submissions[sKey][qKey] || ""}</td>
						</>)}

				</tr>

				</>)  }

		</table>

	</>
}

function ParticipantForm() {

	let testState = useStore.getState()

	let chosenLarp = testState.organiserHandler.chosenLarp


	if(chosenLarp == "") {
		return <></>
	}

	let larp = testState.larps[chosenLarp]

	let participantForm = larp.participantForm 

	let submissions = participantForm.submissions



	return <>
	<h1>Deltagarformulär</h1>

	
	<h2>Frågor</h2>

	{participantForm?.questions ? Object.values(participantForm.questions).map((q, index) => <>
	
	<div className="question">
	{q.saved && <><QuestionViewer question={q}/></>}
	{!q.saved && <><QuestionCreator question={q} /> </>}
	</div>
	

	</>) : ""}
	
	<br />

	<Button onClick={() => addQuestionToParticipantForm()}>Lägg till Fråga</Button>

	<h2>Svar</h2>
	{submissions && <ParticipantSubmissionsViewer submissions={submissions} />}



	</>


}


const OrganiserViewer = () => { 


	let now = moment()



	let testState = useStore.getState()
	let account = testState.account

	if(!account) {
		return <></>
	}


	let larps = testState.larps




	let larpsIOrganise = Object.values(larps).filter((l) => {

		return l.organisers.includes(account.uid) && moment(l.endDate) > now

	})


	return <>

		<select id="larps" onChange={e => chooseLarp(e.currentTarget.value)}>

                        <option value="">-</option>
                        {larpsIOrganise.map((l) => (<option value={l.id}> {  larps[l.id].name  } </option>)  ) }  
              </select> 

        <BasicInfo />
        <ParticipantForm />

	</>


}

export default OrganiserViewer