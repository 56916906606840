import {chasille} from './fiefs/chasille'
import {ailerouge} from './fiefs/ailerouge'
import {nartinell} from './fiefs/nartinell'
import {bogslan} from './fiefs/bogslan'
import {nelone} from './fiefs/nelone'
import {vast_passant} from './fiefs/vast-passant'
import {ost_passant} from './fiefs/ost-passant'
import {valliere} from './fiefs/valliere'
import {chamilmont} from './fiefs/chamilmont'
import {lagarde} from './fiefs/lagarde'
import {valmont} from './fiefs/valmont'
import {tres_stellas} from './fiefs/tres_stellas'
import {gonesse} from './fiefs/gonesse'


export const PERSONAL_INFO_MAPPER = {
                    name: "För- och Efternamn ",
                    birthyear: "Födelseår",
                    phoneNumber: "Telefonnummer",
                    iceName: "ICE-kontakt (namn)",
                    iceNumber: "ICE-kontakt (telefonnummer)",
                    foodAllergies: "Matallergier",
                    allergies: "Andra allergier",
                    medication: "Mediciner",
                    foodPreference: "Matpreferenser",
                    foodRestrictions: "Matrestriktioner"
}


export const ALLCOUNTRIES = ["Okänt",
    "Albeir",
    "Lazulia",
    "Libra",
    "Nexalia",
    "Rasvania",
    "Taronne",
    "Tekozad",
    "Viveira",
    "Gara",
    "Cordotiva",
    "Caphar",
    "Romembergh",
    "Telam",
    "Zelicáj"]

export const CHARCATEGORIES = ["Ädling", "Monsterjägare", "Soldat", 
                               "Musketör", "Alkemist", "Häradsmagiker", 
                               "Magiker", "Licensmagiker", "Vildmagiker", "Caedux", "Paladin", "Människa", "Alv", "Orch", "Halvblod"]

export const ALLSPECIES = ["Människa", "Alv", "Orch", "Annat"]

export const CURRENT_EKAYA_DATE = {year: 400, month: 1}

export const WAGELABOUR_BASECOST = 0.0025

export const BASE_QUALITY = 0.8

export const HARD_CODED_QUALITY = 2.5

export const DAY_LABOURS_PER_MONTH = {
    indentured: 7,
    elves: 9
}

export const RISK_OUTCOMES = {
    1: [0.60, 0.75, 0.90, 1.00, 1.00, 1.00, 1.00, 1.10, 1.20, 1.30],
    2: [0.25, 0.50, 0.75, 0.90, 1.00, 1.00, 1.10, 1.20, 1.50, 2.00],
    3: [0.15, 0.25, 0.50, 0.75, 0.90, 1.10, 1.20, 1.50, 2.00, 3.00],
}


export const CURRENCY_ORDER = [
    "base", "lux", "money", "plot", "labours", "influence"]


export const SWEDISH_CURRENCY_NAMES = {
    base: "Bas",
    lux: "Lyx",
    money: "Kopparörnar",
    plot: "Plot",
    labours: "Dagsverken",
    influence: "Inflytande"
}

export const SWEDISH_SOLDIER_NAMES = {
    infantry: "Fotsoldater",
    rifleman: "Skyttesoldat",
    nco: "Underbefäl",
    officer: "Officerare",
    cavalry: "Kavalleri",
    dragoon: "Dragoner"
}


/* 
fishing = existence of water suitable for fishing
forest = how big and how good forest
arable = how well can the land be farmed (wheat, rye, beans, corn, etc)
pastoral = how well can animals graze
vines = olives and wine
orchard = fruit from trees
bushes: berries and stuff
ore = chance of finding minerals in the ground
*/
export const QUALITIES_BY_DUCHY = {
    guille: {
        arable: 0.8,
        brambles: 1.1,
        fishing: 1.3,
        forest: 0.7,
        ore: 1.2,
        pastoral: 0.9,
        orchard: 1.0,
        vines: 0.6
    },
    chamilmont: {
        arable: 0.8,
        brambles: 1.3,
        fishing: 0.6,
        forest: 1.0,
        ore: 0.9,
        pastoral: 1.2,
        orchard: 1.1,
        vines: 0.7
    },
    ailerouge: {
        arable: 1.0,
        brambles: 1.2,
        fishing: 1.0,
        forest: 0.9,
        ore: 1.3,
        pastoral: 1.1,
        orchard: 0.7,
        vines: 0.6
    },
    camulia: {
        arable: 1.2,
        brambles: 1.0,
        fishing: 0.9,
        forest: 0.7,
        ore: 0.6,
        pastoral: 1.3,
        orchard: 1.1,
        vines: 0.8
    },
    lizania: {
        arable: 1.0,
        brambles: 0.7,
        fishing: 1.2,
        forest: 0.9,
        ore: 0.6,
        pastoral: 0.8,
        orchard: 1.1,
        vines: 1.3
    },

    foretchasse: {
        arable: 0.9,
        brambles: 1.0,
        fishing: 0.6,
        forest: 1.3,
        ore: 0.7,
        pastoral: 1.1,
        orchard: 1.2,
        vines: 0.8
    },
}


export const CURRENCY_NAMES = {
    base: "Bas",
    lux: "Lyx",
    money: "Pengar",
    plot: "Plot",
    influence: "Inflytande",
    labours: "Dagsverken",
    APPLE: "Äpplen",
}

export const LIFESTYLES = {
    poor: {base: 1, lux: 0, money: 10},
    normal: {base: 2, lux: 0.5, money: 500},
    rich: {base: 3, lux: 2, money: 2000}

}

export const EMPLOYEES = {

    steward: {base: 4, lux: 2, money: 1000},
    hunter: {base: 1.5, money: 200}
}
export const ANIMALS = {
    mule: {base: 2,},
    ridingHorse: {base: 2},
    warHorse: {base: 4, money: 100},
    dog: {base: 1.0},
}

export const SOLDIERS = {
    infantry: {base: 2, lux: 0.5, money: 200},
    rifleman: {base: 2, lux: 0.5, money: 200},
    nco: {base: 2, lux: 1, money: 400},
    officer: {base: 4, lux: 2, money: 2000, labours: 3},
    cavalry: {base: 8, lux: 2, money: 300, labours: 3},
    dragoon: {base: 8, lux: 3, money: 300, labours: 3}
}


export const TARONNE_FIEFS = {
    lagarde: lagarde,
    chasille: chasille,
    ailerouge: ailerouge,
    nartinell: nartinell,
    bogslan: bogslan,
    nelone: nelone,
    vast_passant: vast_passant,
    valliere: valliere,
    chamilmont: chamilmont,
    valmont: valmont,
    tres_stellas: tres_stellas,
    ost_passant: ost_passant,
    gonesse: gonesse
}



