//AccountViewer.js


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab} from "semantic-ui-react";
import './App.css';

import {useStore, updateTheStore} from "./newState";

import {getCopyOf} from './utilityFunctions'

import * as fb from './firebaseHandler'

function changeLarpInfo(key, newValue, acc) {

    let account = getCopyOf(acc)

    if(!account['personal']) {
        account.personal = {}
    }

    if(!account.personal[key]) {
        account.personal[key] = ""
    }

    account.personal[key] = newValue

    updateTheStore(s => {s.account = account})
}



function editProfile() {
	updateTheStore(s => {s.profile.editing = true})
}
function editLarpInfo() {
    updateTheStore(s => {s.profile.editingLarpInfo = true})
}

async function saveLarpInfo() {

    updateTheStore(s => {s.profile.editingLarpInfo = false})

    let state = useStore.getState()

    let account = state.account

    await fb.writeAccount(account)

}

const AccountViewer = ({ isOwner = false }) => {

	let account = useStore(s => s.account)

    let editingLarpInfo = useStore(s => s.profile.editingLarpInfo)


	if(!account) {
		return <></>
	}

	let displayName = ""

	if(account.name.public && account.name.value !== "") {
		displayName = account.name.value
	} else {
		displayName = account.nickname?.value || ""
	}

	if(account.pronouns && account.pronouns.public && account.pronouns.value !=="" ) {
		displayName = displayName + " (" + account.pronouns.value + ")"
	}

	if(isOwner) {
		return (
			<>
       	<h2>{displayName}</h2>
       	<h3>Din profil</h3>
        <div className="test">
        	
           <div>
        	<table>
                <tr className="alt-table">
                    <td ><FontAwesomeIcon icon="envelope" size="2x" className="middle" /> </td>
                    <td className="lefty">{account.email.value}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td><FontAwesomeIcon icon="fa-brands fa-discord" size="2x" className="middle" /> </td>
                    <td className="lefty">{account.discord && account.discord.value}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td><FontAwesomeIcon icon="user" size="2x" /></td>
                    <td className="lefty">{account.nickname && account.nickname.value}</td>
                </tr>
                <tr className="alt-table">
                    <td><FontAwesomeIcon icon="user" size="2x" /></td>
                    <td className="lefty">{account.name && account.name.value}</td>
                </tr>
            </table>
			</div> 

        </div>
        <br />
      	<p><Button onClick={() => editProfile()}> Redigera Profil</Button></p>

        <h3>Lajv-relaterad Info</h3>

        <div className="test">
            
           <div>

        {editingLarpInfo && <>
        <table>
                <tr className="alt-table">
                    <td>För- och Efternamn </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.name} onChange={e=>changeLarpInfo("name",e.currentTarget.value, account)} /></td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Födelseår </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.birthyear} onChange={e=>changeLarpInfo("birthyear",e.currentTarget.value, account)} /></td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Telefonnummer </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.phoneNumber} onChange={e=>changeLarpInfo("phoneNumber",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>ICE-kontakt (namn) </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.iceName} onChange={e=>changeLarpInfo("iceName",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>ICE-kontakt (telefonnummer) </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.iceNumber} onChange={e=>changeLarpInfo("iceNumber",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>Matallergier </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.foodAllergies} onChange={e=>changeLarpInfo("foodAllergies",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>Andra Allergier </td><td className="lefty">
                    <Input size="small" style={{width: 250}} type='text' value={account.personal.allergies} onChange={e=>changeLarpInfo("allergies",e.currentTarget.value, account)} /></td>
                    
                   
                </tr>
                <tr className="alt-table">
                    <td>Medication </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.medication} onChange={e=>changeLarpInfo("medication",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>Matpreferenser </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.foodPreference} onChange={e=>changeLarpInfo("foodPreference",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                <tr className="alt-table">
                    <td>Matrestriktioner </td>
                    <td className="lefty"><Input size="small" style={{width: 250}} type='text' value={account.personal.foodRestrictions} onChange={e=>changeLarpInfo("foodRestrictions",e.currentTarget.value, account)} /></td>
                    
                    
                </tr>
                
            </table>
            </>
          }   

        {!editingLarpInfo && <>
        <table>
                <tr className="alt-table">
                    <td>För- och Efternamn </td>
                    <td className="lefty">{account.personal && account.personal.name}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Födelseår </td>
                    <td className="lefty">{account.personal && account.personal.birthyear}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Telefonnummer </td>
                    <td className="lefty">{account.personal && account.personal.phoneNumber}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>ICE-kontakt (namn) </td>
                    <td className="lefty">{account.personal && account.personal.iceName}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>ICE-kontakt (telefonnummer) </td>
                    <td className="lefty">{account.personal && account.personal.iceNumber}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Matallergier </td>
                    <td className="lefty">{account.personal && account.personal.foodAllergies}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Andra Allergier </td>
                    <td className="lefty">{account.personal && account.personal.allergies}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Medication </td>
                    <td className="lefty">{account.personal && account.personal.medication}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Matpreferenser </td>
                    <td className="lefty">{account.personal && account.personal.foodPreference}</td>
                    
                </tr>
                <tr className="alt-table">
                    <td>Matrestriktioner </td>
                    <td className="lefty">{account.personal && account.personal.foodRestrictions}</td>
                    
                </tr>
                
            </table>
            </>
          }
            </div>

            </div>


        <br />
          {!editingLarpInfo && <><p><Button onClick={() => editLarpInfo()}> Redigera Lajvinformation</Button></p></>}
          {editingLarpInfo && <><p><Button onClick={() => saveLarpInfo()}> Spara Lajvinformation</Button></p></>}


        </>
		)
	}
    return (
    	<>
       	<h2>{account.name.value}</h2>
        <div >
        	
            {account.email?.public && <div><FontAwesomeIcon icon="envelope" />: {account.email.value} <br /></div> }
        	{account.discord?.public && <div><FontAwesomeIcon icon="fa-brands fa-discord" />: {account.discord && account.discord.value} </div> }   

        </div>
        </>
    )
};

export default AccountViewer;