export const chasille = {type: "county", name: "Chasille", owner: "Joaquin DeFeudoiseau", duchy: "lizania", 
              tmpId: "fief-154f618d-f15a-453c-885a-32e68266f846",
              baronies:[
                    {name: "Etrette", owner: "Joaquin DeFeuDoiseau", size:53627},
                    {name: "Antolun", owner: "Joaquin DeFeuDoiseau", size:31346},
                    {name: "Montauves", owner: "Joaquin DeFeuDoiseau", size:20692},
                    {name: "Deverandeau", owner: "Joaquin DeFeuDoiseau", size:9906},
                    {name: "Clonanard", owner: "Joaquin DeFeuDoiseau", size:65190},
                    {name: "Houinne", owner: "Joaquin DeFeuDoiseau", size:26691},
                    {name: "Camrac", owner: "Joaquin DeFeuDoiseau", size:19217}	],
              landSplit: ["PEAR","PEAR","PEAR","PEAR","PEAR","PEAR","VINEYARD","VINEYARD","VINEYARD","ARABLE"],
              
              businesses: [{qualityBase: "fishing", 
                            time: 1, 
                            risk: 2, 
                            name: "Fiske",
                            product: "fisk",
                            revenues: {base: 1.0, lux: 0,money: 0,plot:0.0,influence:0.0,labours: 0},
                            monthlyUnitCost: {base: 0, lux: 0, money: 0, plot: 0, influence: 0, labours: 25},
                            setupUnitCost:{base: 12.0, lux: 0, money: 0, plot: 0, influence: 0, labours: 25},
                            setupCost: {base: 0.0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
                            unitName: "boats",
                            units: 100,
                            canAddUnits: true,
                            canLoseUnits: true
                          },{qualityBase: "", 
                            time: 36, 
                            risk: 3, 
                            name: "Pärondestilleri",
                            product: "päronkonjak",
                            revenues: {base: 0.0, lux: 1.0,money: 0.0,plot:0.0,influence:0.0,labours: 0},
                            monthlyUnitCost: {base: 10, lux: 0, money:0, plot: 0, influence: 0, labours: 5},
                            setupUnitCost:{base: 1.0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
                            setupCost: {base: 10.0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
                            unitName: "tunna",
                            units: 100,
                            created: {year: 468, month: 6} }],
                productionBusinesses: [{
                                        key: "SHIPYARD",
                                        name: "Skeppsvarv",
                                        unitName: "dock",
                                        units: 0,
                                        setupTime: 12,
                                        setupUnitCost: {base: 1000, lux: 50, money: 0, plot: 0, influence: 0, labours: 10000},
                                        addedUnitsPerMonth: 0.0833334,
                                        notBuildingCost: {base: 100, lux: 5, money: 0, plot: 0, influence: 0, labours: 1000},
                                        addedUnits: 0,
                                        created: {year: 515, month:6},
                                        currentlyBuilding: [],
                                        queue: ["FLAGSHIP", "BRIG_ONE", "SCHOONER_ONE", "LUGGER_ONE", "BRIG_ONE", "SCHOONER_ONE", "LUGGER_ONE", 
                                                "BRIG_ONE", "SCHOONER_ONE", "LUGGER_ONE","FRIGATE_ONE","FRIGATE_ONE","FRIGATE_ONE"],
                                        products: {
                                            BRIG_ONE: {
                                                        cost: {base: 5300, lux: 2700,money: 800000,plot:0,influence:0,labours: 40000},
                                                        buildTime: 5,
                                                        type: "Brigg, klass 1",
                                                        name:""
                                                      },
                                            CORVETTE_ONE: {
                                                        cost: {base: 8000, lux: 4000,money: 1200000,plot:0,influence:0,labours: 60000},
                                                        buildTime: 7,
                                                        type: "Korvette, klass 1",
                                                        name:""
                                                      },
                                            FRIGATE_ONE: {
                                                        cost: {base: 13500, lux: 6600,money: 2000000,plot:0,influence:0,labours: 100000},
                                                        buildTime: 12,
                                                        type: "Fregatt, klass 1",
                                                        name:""
                                                      },
                                            LINE_SHIP_ONE: {
                                                        cost: {base: 32000, lux: 16000,money: 4800000,plot:0,influence:0,labours: 240000},
                                                        buildTime: 30,
                                                        type: "Linjeskepp, klass 1",
                                                        name:""
                                                      },
                                            FLAGSHIP: {
                                                        cost: {base: 48000, lux: 24000,money: 7200000,plot:0,influence:0,labours: 360000},
                                                        buildTime: 48,
                                                        type: "Flaggskepp",
                                                        name:""
                                                      },
                                            SCHOONER_ONE: {
                                                        cost: {base: 6600, lux: 3000,money: 960000,plot:0,influence:0,labours: 48000},
                                                        buildTime: 4,
                                                        type: "3 Skonare, klass 1",
                                                        name:""
                                                      }, 
                                            LUGGER_ONE: {
                                                        cost: {base: 500, lux: 200,money: 80000,plot:0,influence:0,labours: 4000},
                                                        buildTime: 1,
                                                        type: "2 Lugger, klass 1",
                                                        name:""
                                                      }
                                        }
                                      }],
                mines: [{
                type: "SILVER",
                timeLeft: 492,
                revenue: 83000, 
                units: 1, 
                created: {year: 495, month: 4}, 
                active: true}]
    }