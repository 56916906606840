//businesses.js


//MONEY IS IN COPPER/EAGLES


const sampleBusiness = {
    owner: "123", // uuid of character the business belongs to
    id: "123", // uuid
    name: "",  // string
    units: 1,  // how many units of income and cost are there in the business
    revenues: {base: 0.2, lux: 0.2, money: 0.2,
               plot: 0.2, influence: 0.1, labours: 0.1}, //what different revenues are granted by the business, in decimals adding up to 1
    costPerUnit: {base: 1, lux: 1, money: 1, plot: 1, influence: 1,labours: 1}, // costs per unit, in absolute numbers
    unitName: "", // What are the units
    risk: 1, // number, indicating how risky the business is. Higher risk, higher reward
    quality: 1, // How good is the things produced by the business
    canLoseUnits: false, // Can poor outcome result in loss of units?
    initialSetupCosts: {base: 1, lux: 1, money: 1, plot: 1, influence: 1,labours: 1},  // costs to set up the business, absolute numbers (per unit?)
    businessStarted: {year: 1, month: 1},
    timeBeforeProductive: 1

}




const farmTemplate = {
    owner: "",
    id: "",
    name: "",
    units: 0,
    product: {},
    unitName: "hectares",
    quality: 1,
    canLoseUnits: false,
    
    businessStarted: {year: 0, month: 0},
}

export const businessTemplates = {
    Farm: farmTemplate
}


export const foxCompany = {
            id: 1,
            name: "Fox Company",
            units: 10,
            risk: 4, //1-5,
            costPerUnit: {base: 2, lux: 0.5, money: 200, plot: 0, influence: 0,labours: 0},
            revenues: {base: 0, lux: 0.5, money: 0.5, plot: 0, influence: 0, labours: 0},
            canLoseUnits: true,
            owner: 1,
            quality: 3,
            unitName: "troops"
          }


export const arableLand = {
			id: 1,
            name: "Arable Land",
            units: 10,
            risk: 1, //1-5,
            costPerUnit: {base: 0, lux: 0, money: 0, plot: 0, influence: 0,labours: 5},
            revenues: {base: 1, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
            canLoseUnits: true,
            owner: 1,
            quality: 3,
            unitName: "hectares"
          }

