import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf, loadAllRelevantFromDB} from "./utilityFunctions";
import * as constants from './constants'
import {get} from "firebase/database";
import * as ch from './currencyHandler'
import {v4 as uuid} from 'uuid';
import * as fb from './firebaseHandler'
import * as poker from './poker'

async function saveLarp(existingLarp = null) {


    let testState = useStore.getState()

    let auth = testState.auth

    let lName = document.getElementById("larp_name").value
    let lLocation = document.getElementById("larp_location").value
    let lStartDate = document.getElementById("larp_start").value
    let lEndDate = document.getElementById("larp_end").value
    let organisers = testState.adminHandler.larpOrganisers

   
    let notDone = false

    if(lName === "") {
        document.getElementById("larp_name").style.backgroundColor = "red"
        notDone = true
    }
    if(lLocation === "") {
        document.getElementById("larp_location").style.backgroundColor = "red"
        notDone = true
    }
    if(lStartDate === "") {
        document.getElementById("larp_start").style.backgroundColor = "red"
        notDone = true
    }

    if(lEndDate === "") {
        document.getElementById("larp_end").style.backgroundColor = "red"
        notDone = true
    }

    if(organisers === "") {
        document.getElementById("larp_organisers").style.backgroundColor = "red"
        notDone = true
    }

    if(notDone) {
        return
    }


    let owner = auth?.currentUser?.uid

    if (!owner) {
        return
    }

    let larp = {
        id: uuid(), 
        name: lName,
        location: lLocation,
        startDate: lStartDate,
        endDate: lEndDate,
        organisers: organisers,
        characters: [],
        public: false
    }


    await fb.writeLarp(larp)

    updateTheStore(s => {
        s.adminHandler.creatingLarp = false
    })

    await loadAllRelevantFromDB()
}

function removeOrganiser(index) {

  let testState = useStore.getState()
  
  let larpOrganisers = [...testState.adminHandler.larpOrganisers]

  if(index > larpOrganisers.length) {
    return
  }

  larpOrganisers.splice(index,1)

  updateTheStore(s => {s.adminHandler.larpOrganisers = larpOrganisers})

}

function addOrganiser(key) {

  if(key === "-") {
    return
  }
  let testState = useStore.getState()

  
  let larpOrganisers = [...testState.adminHandler.larpOrganisers]

  larpOrganisers.push(key)

  updateTheStore(s => {s.adminHandler.larpOrganisers = larpOrganisers})

}

function createLarp(){

  let testState = useStore.getState()

  let adminHandler = testState.adminHandler


  updateTheStore(s => {s.adminHandler.creatingLarp = true})

}


const AdminViewer = () => {

    let testState = useStore.getState()
    let currentUser = testState.auth?.currentUser

    let adminHandler = testState.adminHandler

    let organisers = adminHandler.larpOrganisers
    let profiles = {}
    let allProfiles = {}


    for(const key of Object.keys(testState.profiles)) {

      let publicName = testState.profiles[key].name.public ? testState.profiles[key].name.value : (testState.profiles[key].nickname.public ? testState.profiles[key].nickname.value : "") 
      if(publicName !== "") {
        allProfiles[key] = publicName
        if(!organisers.includes(key) ) {
            profiles[key] = publicName

        }

      }
    }

    if(!currentUser) {
        return <><div>Not logged in. </div></>
    }


    return <><div>
        <Button onClick={() => createLarp()} >Skapa nytt lajv.</Button>
        {adminHandler.creatingLarp && <>
              <p>Lajvets namn: <input id="larp_name"/></p>
              <p>Lajvets plats: <input id="larp_location"/></p>
              <p>Lajvets startdatum: <input type="date" id="larp_start"/></p>
              <p>Lajvets slutdatum: <input type="date" id="larp_end"/></p>
              <h4>Arrangörer:</h4>{organisers.map((o, index) => (<> <div>{allProfiles[o]} <Button onClick={() =>removeOrganiser(index)} color='red' size='mini'>X</Button></div>  <br /> </>))}
              <div>Lägg till arrangör:</div> 
              <select id="larp_organisers" onChange={e => addOrganiser(e.currentTarget.value)}>

                        <option value="-">-</option>
                        {Object.keys(profiles).map((key) => (<option value={key}> {  profiles[key]  } </option>)  ) }  
              </select> 
              
        </>}
        <Button onClick={() => saveLarp()} >Spara Lajv</Button>
        <br />
        <br />

        <Button onClick={() => poker.run(1200, false)} >Spela Poker</Button>

    </div>

    <div>
    { Object.keys(profiles).map((p) => <><div>{p} - {testState.profiles[p].name.value}  }</div></>)}
    </div>
    </>
};


export default AdminViewer;