//AccountViewer.js


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab} from "semantic-ui-react";
import './App.css';
import './LoggedInHeader.css';

import {logout, getCopyOf} from './utilityFunctions'
import {updateTheStore, useStore} from "./newState";


function toggleFiefPrintView() {

    let testState = useStore.getState()

    let fiefPrintView = !testState.fiefPrintView


    updateTheStore(s => {s.fiefPrintView = fiefPrintView})

    console.log(useStore.getState().fiefPrintView)

}

function editProfile(state, save_state) {

	updateTheStore(s => {s.profile.editing = true})
}

const LoggedInHeader = () => {

    let testState = useStore.getState()
    let fiefPrintView = testState.fiefPrintView

	return (<>

        <div className="header-container">
            <div className="left"></div>
            <div className="center">
                <div className="headerDiv"><img className="headerLogo" src="/gragripen.png" /></div> 
            </div>
            <div className="right">
                <div className="rightPartHeader"><Button onClick={()=>logout()}> Sign Out</Button></div>
            </div>

       </div>
    </>
    )
};

export default LoggedInHeader;